import React from 'react'

import { paths } from 'mmfintech-backend-api'

import Accounts from './views/account/Accounts'
import Dashboard from './views/dashboard'
import Expenses from './views/requests/Requests'
import Invoices from './views/invoices/Invoices'
import Login from './views/auth/Login'
import ReferralProgram from './views/referral/ReferralProgram'
import Savings from './views/savings/Savings'
import Transactions from './views/transactions/Transactions'
import CashVoucher from './views/cashVouchers/CashVoucher'

const BusinessAccount = React.lazy(() => import('./views/businessAccount/BusinessAccount'))
const Exchange = React.lazy(() => import('./views/banking/Exchange'))
const LazyInvite = React.lazy(() => import('./views/auth/Invite'))
const InvoiceEdit = React.lazy(() => import('./views/invoices/InvoiceEdit'))
const InvoiceUploadedEdit = React.lazy(() => import('./views/invoices/InvoiceUploadedEdit'))
const LazySignup = React.lazy(() => import('./views/auth/Signup'))
const LazyFinalStatus = React.lazy(() => import('./views/transactions/TransactionFinalStatus'))
const VerificationSumSub = React.lazy(() => import('./views/onboarding/SumSubVerification'))
const Withdraw = React.lazy(() => import('./views/banking/SendMoney/SendMoney'))

// prettier-ignore
export const nonProtectedRoutes = [
  { path: paths.login(), component: Login },
  { path: paths.signUpViaInviteLink(':referralCode'), component: LazySignup },
  { path: paths.signUp(), component: LazySignup },
  { path: paths.inviteJeton(':link'), exact: true, component: LazyInvite },
  { path: paths.invite(':link'), exact: true, component: LazyInvite },
  { path: paths.checkEmail(), component: React.lazy(() => import('./views/auth/CheckEmail')) },
  { path: paths.forgotPassword(), component: React.lazy(() => import('./views/auth/ForgotPassword')) },
  { path: paths.changePasswordEmail(), component: React.lazy(() => import('./views/auth/ForgotPasswordMessage')) },
  { path: paths.passwordReset(), component: React.lazy(() => import('./views/auth/ResetPasswordMessage')) },
  { path: paths.handlePasswordReset(), component: React.lazy(() => import('./views/auth/ResetPassword')) },
  { path: paths.handlePasswordResetInvited(), component: React.lazy(() => import('./views/auth/SetPassword')) },
  { path: paths.handleEmailVerify(), component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.handleInvitationVerify(), component: React.lazy(() => import('./views/auth/VerifyInvitation')) },

  { path: paths.businessAccount(), component: BusinessAccount },
  { path: paths.offshoreBank(), component: BusinessAccount },
  { path: paths.adsDebitCard(), component: BusinessAccount },
  { path: paths.adsBusinessBankAccount(), component: BusinessAccount },
  { path: paths.adsIbanMT(), component: BusinessAccount },

  { path: paths.invoices.checkout(':magicToken'), component: React.lazy(() => import('./views/invoices/InvoiceCheckout')) },

  { path: paths.error404(), component: React.lazy(() => import('./views/auth/Error404')) },

  { path: paths.banking.finalStatus.fail(':method'), component: LazyFinalStatus, params: { isSuccess: false } },
  { path: paths.banking.finalStatus.success(':method'), component: LazyFinalStatus, params: { isSuccess: true } }
]

// prettier-ignore
export const protectedRoutes = [
  { path: paths.root(), redirect: paths.dashboard() },

  { path: paths.banking.transferSuccess(), component: React.lazy(() => import('./views/banking/elements/SuccessPane')) },

  { path: paths.dashboard(), component: Dashboard },
  { path: paths.profile(), component: React.lazy(() => import('./views/profile')) },
  { path: paths.security(), component: React.lazy(() => import('./views/security')) },
  { path: paths.userManagement(), component: React.lazy(() => import('./views/users')) },

  { path: paths.banking.deposit(), component: React.lazy(() => import('./views/banking/deposit/Deposit')) },
  { path: paths.banking.exchangeFromTo(':fromCurrency', ':toCurrency'), component: Exchange },
  { path: paths.banking.exchange(), component: Exchange },
  { path: paths.banking.sendBankTransfer(), exact: true, component: React.lazy(() => import('./views/banking/BankTransfer')) },
  { path: paths.banking.sendCrypto(), exact: true, component: React.lazy(() => import('./views/banking/CryptoTransfer')) },
  { path: paths.banking.sendTransfer(), exact: true, component: React.lazy(() => import('./views/banking/InternalTransfer')) },
  { path: paths.banking.sendDigitalWallet(), exact: true, component: React.lazy(() => import('./views/banking/WalletTransfer')) },
  { path: paths.banking.sendIntraAccountTransfer(), exact: true, component: React.lazy(() => import('./views/banking/IntraAccountTransfer')) },
  { path: paths.banking.sendBatchPayment(), exact: true, component: React.lazy(() => import('./views/banking/BatchPayment')) },
  { path: paths.banking.sendTo(':recipientId'), component: Withdraw },
  { path: paths.banking.send(), component: Withdraw },
  { path: paths.banking.expenses(), component: Expenses },

  { path: paths.ibanIssuing(), exact: true, component: React.lazy(() => import('./views/account/IbanRequest')) },
  { path: paths.ibanIssuingPreview(), exact: true, component: React.lazy(() => import('./views/banking/preview/IbanRequestPreview')) },

  { path: paths.banking.transactions.details(':transactionId'), component: React.lazy(() => import('./views/transactions/TransactionDetails')) },
  { path: paths.banking.transactions.listByAccountId(':accountId'), component: Transactions },
  { path: paths.banking.transactions.list(), component: Transactions },

  { path: paths.recipients.details(':recipientId'), component: React.lazy(() => import('./views/recipients/RecipientDetails')) },
  { path: paths.recipients.list(), component: React.lazy(() => import('./views/recipients')) },
  { path: paths.banking.accounts.list(), component: Accounts },

  { path: paths.referralProgram(), component: ReferralProgram },
  { path: paths.more.savings(), component: Savings },

  { path: paths.developer(), component: React.lazy(() => import('./views/developer/Developer')) },

  { path: paths.customerInvoices(':payerId'), component: React.lazy(() => import('./views/customers/CustomerDetails')) },
  { path: paths.customers(), component: React.lazy(() => import('./views/customers/Customers')) },

  { path: paths.invoices.viewByToken(':magicToken'), component: React.lazy(() => import('./views/invoices/InvoicePreview')) },

  { path: paths.invoices.uploadEdit(':invoiceId'), component: InvoiceUploadedEdit },
  { path: paths.invoices.upload(), component: InvoiceUploadedEdit },
  { path: paths.invoices.addForCustomer(':customerId'), component: InvoiceEdit },
  { path: paths.invoices.add(), component: InvoiceEdit },
  { path: paths.invoices.send(':invoiceId'), component: React.lazy(() => import('./views/invoices/InvoiceSend')) },
  { path: paths.invoices.edit(':invoiceId'), component: InvoiceEdit },
  { path: paths.invoices.listByStatus(':status'), component: Invoices },
  { path: paths.invoices.list(), component: Invoices },

  { path: paths.onboarding.documents(), exact: true, component: React.lazy(() => import('./views/onboarding/AdditionalDocuments')) },
  { path: paths.onboarding.questionnaireKyb(), exact: true, component: React.lazy(() => import('./views/onboarding/QuestionnaireKYB')) },
  { path: paths.onboarding.questionnaireKybSuccess(), exact: true, component: React.lazy(() => import('./views/onboarding/elements/KybSuccess')) },
  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, component: VerificationSumSub },
  { path: paths.onboarding.sumSub.continue(), exact: true, component: VerificationSumSub },

  { path: paths.cards.list(), component: React.lazy(() => import('./views/card')) },

  { path: paths.more.cashVoucher('buy'), component: CashVoucher },
  { path: paths.more.cashVoucher('list'), component: CashVoucher },
  { path: paths.more.cashVoucher('redeem'), component: CashVoucher },
]
