import { useHistory } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import {
  AccessDeniedContainer,
  AccessDeniedContent,
  AccessDeniedSubtitle,
  AccessDeniedTitle
} from './AccessDenied.styled'

export const AccessDenied = () => {
  const history = useHistory()

  const handleBackClick = () => {
    history.push(paths.dashboard())
  }

  return (
    <AccessDeniedContainer>
      <AccessDeniedContent>
        <AccessDeniedTitle centered>{tr('FRONTEND.ACCESS_DENIED.TITLE', 'Access Denied')}</AccessDeniedTitle>
        <AccessDeniedSubtitle>
          {tr(
            'FRONTEND.ACCESS_DENIED.DESCRIPTION',
            'Sorry but it seems that you cannot access this area because your account does not have the right to view this page.'
          )}
        </AccessDeniedSubtitle>
        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.ACCESS_DENIED.BACK_BUTTON', 'Back to overview')}
          onClick={handleBackClick}
          data-test='button-back'
        />
      </AccessDeniedContent>
    </AccessDeniedContainer>
  )
}
