import styled from 'styled-components'

export const CardOrderModalWrapper = styled.div<{ large?: boolean }>`
  padding: 4rem;
  max-width: 96vw;
  width: ${({ large }) => (large ? '75rem' : '50rem')};

  .vertically-centered {
    display: flex;
    align-items: center;
  }

  .card-order-header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;

    .card-order-stepper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .card-order-header-title {
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      color: #131e3d;
    }
  }

  .card-order-type-selection {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    padding-bottom: 4rem;
  }

  .card-order-issuing-message {
    margin-bottom: 2.4rem;
    text-align: center;
  }

  .card-order-phone-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-bottom: 3rem;

    .card-order-phone-icon {
      width: 9.5rem;
      height: 9.5rem;
      border-radius: 50%;
      background-color: var(--card-order-phone-background);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--card-order-phone-shadow);

      svg {
        height: 5.4rem;
        width: 5.4rem;
      }
    }

    .card-order-phone-instructions {
      width: 100%;
      text-align: center;

      font-size: 1.4rem;
      font-weight: 400;
      line-height: normal;
    }

    .core-input-wrapper {
      width: 100%;
      margin-bottom: 13rem;
    }
  }
  .card-order-currency-select {
    padding: 3.2rem 0;
    min-height: 30rem;
  }
  .card-order-modal-buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    .card-order-modal-back-button {
      background-color: #fff;
      color: rgba(19, 30, 61, 1);
      &:hover {
        background-color: rgba(19, 30, 61, 1);
        color: #fff;
      }
    }
  }

  .card-order-checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;

    background-color: #ffffff80;
    border: 1px solid #dcdfe5;
    border-radius: 50%;

    .checkbox-icon {
      background-color: transparent;
      border: none;
      border-radius: 50%;
      width: 1rem !important;
      height: 1rem !important;
    }
    &.checked {
      .checkbox-icon {
        background-image: none;
        background-color: #6bcdfd;
      }
    }
  }
`

export const CardTypeWrapper = styled.div`
  position: relative;
  border: 1px solid #dcdfe5;
  border-radius: 1rem;
  transition: height 0.3s ease;

  padding: 1.6rem;

  &.selected {
    border: 1px solid #6bcdfd;

    .card-order-check-icon {
      height: 100%;
      align-self: start;
    }
  }

  &.unavailable {
    opacity: 50%;
    cursor: default;
    user-select: none;
  }
`

export const CardTypeContainer = styled.div`
  cursor: pointer;
  position: relative;
  background-color: white;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.6rem;

  margin: 0;

  .card-order-card-icon {
    position: relative;
    svg {
      display: block;
      position: unset;
    }
  }

  .card-order-card-content {
    width: auto;

    color: #575e6c;
    font-size: 12px;
    font-weight: 400;
    text-align: left;

    .card-order-card-title {
      color: #131e3d;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      margin-bottom: 4px;
    }
  }

  .card-brand-icon {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
  }

  .unavailable & {
    cursor: default;
  }
`

export const CardBrandsWrapper = styled.div`
  width: 100%;
  margin-top: 1.6rem;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 0.8rem;

  transition: all 0.3s ease;
`
