import { useState } from 'react'

import { tr } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import { CheckBoxGroup } from '../../../components'
import { CommonPageTitle } from '../../../components/jetonStyled'
import { TableCustomizeButtons, TableCustomizeWrapper } from './styled/customizeTableModal.styled'

function CustomizeTableModal({ columns, onClose }) {
  const [visible, setVisible] = useState<string[]>(columns.visible)

  const toggleVisible = (name: string) => {
    if (visible.includes(name)) {
      setVisible(prevState => prevState.filter(v => v !== name))
    } else {
      setVisible(prevState => [...prevState, name])
    }
  }

  return (
    <TableCustomizeWrapper data-test='customize-transactions-table-modal'>
      <CommonPageTitle className='title' normalFontWeight>
        {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMIZE_TITLE', 'Customize Table')}
      </CommonPageTitle>

      <CheckBoxGroup
        items={columns.meta}
        checked={name => visible.includes(name)}
        setChecked={toggleVisible}
        footer={null}
      />

      <TableCustomizeButtons>
        <Button
          type='button'
          text={tr('FRONTEND.TRANSACTIONS.COLUMNS.APPLY_BUTTON', 'Apply')}
          onClick={() => {
            columns.setVisible(visible)
            onClose()
          }}
        />
        <Button type='button' color='secondary' text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')} onClick={onClose} />
      </TableCustomizeButtons>
    </TableCustomizeWrapper>
  )
}

export default CustomizeTableModal
