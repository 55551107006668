import styled from 'styled-components'

import { devices } from '../../../../constants'
import { CommonFlexRow } from '../../../../components/jetonStyled'

export const FilterWrapper = styled.div`
  width: 100%;
  padding: 2rem;
`

export const FilterButtons = styled.div`
  ${CommonFlexRow(2)}
  justify-content: center;
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;

  .top-row {
    ${CommonFlexRow()}
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 1.8rem;
      font-weight: 400;
      color: #131e3d;
    }
  }

  .multiple-inputs {
    @media ${devices.sm} {
      display: flex;
    }
  }

  .checkbox-label {
    color: black;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .input-wrapper {
    margin-bottom: 2rem;
    margin-right: 1.5rem;

    &:last-of-type {
      margin-right: 0;
    }

    & > div {
      width: 100%;
      flex-grow: 1;
      background-color: white;
      border: 1px solid rgba(133, 160, 173, 0.3);
      padding: 0;
    }

    label {
      color: #85a0ad;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
      padding-left: 0;
      text-transform: none;
    }

    input {
      height: auto;
      min-height: 4rem;
      font-size: 1.4rem;
      font-weight: 400;
      padding-left: 1rem;
      padding-right: 1rem;

      &::-webkit-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &:-ms-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &::placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
    }

    span.icon {
      margin-left: 1rem;
      margin-right: 0;
    }
  }

  .select-input {
    margin: 0 1rem;
  }

  [name='from'],
  [name='to'] {
    & > div {
      height: 3.5rem;
      padding: 0 1rem;
      .icon {
        margin-right: 0;
      }
      input {
        cursor: pointer;
      }
    }
  }

  [name='currency'],
  [name='statuses'],
  [name='paymentMethods'] {
    & > div {
      padding-left: 1rem;
      padding-right: 0;
    }
  }
`
