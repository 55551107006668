import { ReactNode } from 'react'

import cn from 'classnames'
import { tr } from 'mmfintech-commons'

import {
  CheckBoxGroupFooter,
  CheckBoxGroupHeader,
  CheckBoxGroupItem,
  CheckBoxGroupWrapper
} from './CheckBoxGroup.styled'

interface CheckBoxItem {
  name: string
  label: string
  localizationKey?: string
}

interface CheckBoxGroupProps {
  footer?: ReactNode
  header?: ReactNode
  items?: CheckBoxItem[]
  checked: (key: string) => boolean
  setChecked: (key: string) => void
}

export const CheckBoxGroup = ({ footer, header, items, checked, setChecked }: CheckBoxGroupProps) => (
  <CheckBoxGroupWrapper onClick={e => e.stopPropagation()}>
    {header && <CheckBoxGroupHeader>{header}</CheckBoxGroupHeader>}
    <div className='item-container'>
      {items?.map((item, index) => {
        const { name, label, localizationKey } = item
        return (
          <CheckBoxGroupItem key={index} onClick={() => setChecked(name)}>
            <span>{tr(localizationKey, label)}</span>
            <span className={cn('checkbox', { checked: checked(name) })} data-test={`toggle-${name}`} />
          </CheckBoxGroupItem>
        )
      })}
    </div>
    {footer && <CheckBoxGroupFooter>{footer}</CheckBoxGroupFooter>}
  </CheckBoxGroupWrapper>
)
