import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  setStep,
  useAppSelector,
  useGetHintsQuery,
  useMyPermissionsQry,
  useSaveHintMutation
} from 'mmfintech-backend-api'
import { GlobalContext, tr, useWindowSize } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import { useAppDispatch } from '../hooks'
import { breakpoints, hints } from '../constants'
import useHidePaymentButtons from '../hooks/useHidePaymentButtons'
import Callout from './Callout'
import { UnlockedFeatureModal } from './UnlockedFeatureModal'
import { StyledPaymentButtons } from './styled/paymentButtons.styled'

import { MerchantUserHintResponse } from 'mmfintech-commons-types'

import DepositIcon from '../images/icons/deposit.svg?react'
import ExchangeIcon from '../images/icons/exchange.svg?react'
import RedDotIcon from '../images/icons/new-dot-icon.svg?react'
import SendIcon from '../images/icons/send.svg?react'

const {
  unlocked: { deposit, exchange }
} = hints

export function PaymentButtons() {
  const { modalShow } = useContext(GlobalContext)
  const dispatch = useAppDispatch()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { data: seenHints } = useGetHintsQuery()

  const [width] = useWindowSize()
  const history = useHistory()
  const visible = useHidePaymentButtons()
  const { hasAnyWritePermission } = useMyPermissionsQry()

  const [saveHint] = useSaveHintMutation()

  const handleDepositClick = () => history.push(paths.banking.deposit())

  const handleSendMoneyClick = () => {
    dispatch(setStep(1))
    history.push(paths.banking.send())
  }

  const handleExchangeClick = () => history.push(paths.banking.exchange())

  const calloutProps = {
    isOpen: true,
    position: 'top' as const,
    alignment: 'right' as const,
    arrowOptions: { showArrow: false },
    offset: { x: '1.5rem', y: '1.5rem' },
    content: <RedDotIcon />
  }

  return visible ? (
    <StyledPaymentButtons>
      {hasAnyWritePermission &&
        (width > breakpoints.xl ? (
          <Button
            className='button deposit-button'
            type='button'
            color='secondary'
            icon={<DepositIcon className='icon' />}
            text={
              <Callout {...calloutProps} hintKey={deposit}>
                {tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
              </Callout>
            }
            onClick={() => {
              handleDepositClick()
              if (
                seenHints &&
                !seenHints?.find((hint: MerchantUserHintResponse) => hint?.hintKey === 'unlocked-depoists')?.isSeen
              ) {
                saveHint({
                  hintKey: deposit
                })
                modalShow({
                  content: <UnlockedFeatureModal type='deposit' />
                })
              }
            }}
            data-test='deposit-button'
            data-for='button-tooltip'
          />
        ) : (
          <div className='mobile-container' onClick={handleDepositClick}>
            <div className='deposit-icon'>
              <DepositIcon className='icon' />
            </div>
            {tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
          </div>
        ))}
      {hasAnyWritePermission &&
        (width > breakpoints.xl ? (
          <Button
            className='button send-button'
            type='button'
            color='secondary'
            icon={<SendIcon className='icon' />}
            text={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
            onClick={handleSendMoneyClick}
            data-test='send-money-button'
          />
        ) : (
          <div className='mobile-container' onClick={handleSendMoneyClick}>
            <div className='send-icon'>
              <SendIcon className='icon' />
            </div>
            {tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
          </div>
        ))}
      {isOwnerOrAdministrator(customerRole) &&
        (width > breakpoints.xl ? (
          <Button
            className='button exchange-button'
            type='button'
            color='secondary'
            icon={<ExchangeIcon className='icon' />}
            text={
              <Callout {...calloutProps} hintKey={exchange}>
                {tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
              </Callout>
            }
            onClick={() => {
              handleExchangeClick()
              if (
                seenHints &&
                !seenHints?.find((hint: MerchantUserHintResponse) => hint?.hintKey === 'unlocked-exchange')?.isSeen
              ) {
                saveHint({
                  hintKey: exchange
                })
                modalShow({
                  content: <UnlockedFeatureModal type='exchange' />
                })
              }
            }}
            data-test='exchange-button'
            data-for='button-tooltip'
          />
        ) : (
          <div className='mobile-container' onClick={handleExchangeClick}>
            <div className='exchange-icon'>
              <ExchangeIcon className='icon' />
            </div>
            {tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
          </div>
        ))}
    </StyledPaymentButtons>
  ) : null
}
