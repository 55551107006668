import styled from 'styled-components'

import { devices } from '../../../constants'

export const StatementWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 50rem;

  padding: 4.5rem 2.5rem;

  @media (min-width: 400px) {
    padding: 4rem 5rem;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const StyledForm = styled.form`
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: white;

  .title {
    display: block;
    text-align: center;
    margin-bottom: 3rem;

    color: #131e3d;
    font-size: 2.4rem;
    font-weight: normal;
    line-height: 2.9rem;
  }

  .asterisk {
    color: #ff3131;
  }

  .radio-button,
  .checkbox-label {
    color: black;
    font-size: 1.4rem;
    font-weight: 400;
  }

  .radio-group {
    display: grid;
    grid-gap: 3.5rem;
    grid-template-columns: auto auto 1fr;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .year-month {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem;
    position: relative;
    margin-bottom: 3rem;

    @media (max-width: 599px) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }

    .input-wrapper {
      & > label {
        margin-bottom: 0.5rem;
        margin-left: 2rem;
        color: #85a0ad;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5rem;
        text-transform: none;
      }
      & > div {
        min-height: 4.6rem;
        border: 1px solid rgba(133, 160, 173, 0.2);
        border-radius: 6px;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }

  .period-wrap {
    width: 100%;
    display: grid;
    margin-bottom: 3rem;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    @media ${devices.sm} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 4rem;
    }
  }

  .button {
    margin-top: 1rem;
    :first-of-type {
      margin-top: 0;
    }
  }
`

export const DateContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  label {
    color: #85a0ad;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
  }
`

export const DateWrapper = styled.div`
  width: 100%;
  height: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0 1.5rem;

  background: #ffffff;
  border: 1px solid rgba(133, 160, 173, 0.2);
  border-radius: 6px;

  color: #131e3d;
  font-style: normal;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
  &.borderless {
    border: none;
  }

  img,
  svg {
    margin-right: 0.8rem;
  }
`
