import styled from 'styled-components'
import { breakpoints } from '../../constants'

export const SidebarContainer = styled.div<{ visible?: boolean; isOpen?: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  transition: width 0.3s ease;
  padding-top: 1rem;
  width: ${props => (props.isOpen ? `${props.theme.sidebar.width}px` : `${props.theme.sidebar.widthCollapsed}px`)};
  background-color: white;

  @media (max-width: ${breakpoints.xl}px) {
    position: relative;
    width: 100%;
    padding: 0 2rem;

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background-image: linear-gradient(to right, transparent, rgba(71, 126, 235, 0.2));
      width: 15%;
    }
  }
`

export const SideBarContent = styled.div`
  display: flex;
  flex-flow: column nowrap;

  overflow-x: hidden;
  overflow-y: auto;

  .sidebar-link {
    display: flex;
    height: 7rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-left: 5px solid transparent;

    color: #8f96a3;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    text-decoration: none;
  }

  .sidebar-link-text {
    margin-top: 1rem;
  }

  .active,
  .sidebar-link:hover {
    background-color: rgba(71, 126, 235, 0.1);
    border-left-color: #0066ff;
    color: #131e3d;
  }

  @media (max-width: ${breakpoints.xl}px) {
    padding: 1rem 0;
    flex-flow: row nowrap;
    gap: 2rem;
    width: 100%;
    white-space: nowrap;
    overflow: scroll hidden;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    .sidebar-link {
      font-size: 1.4rem;
      padding: 0 1rem;
    }

    .active,
    .sidebar-link:hover {
      background-color: rgba(71, 126, 235, 0.1);
      border: none;
      border-radius: 1.5rem;
      color: #131e3d;
    }
  }
`
