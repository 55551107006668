/* eslint-disable react/no-deprecated */
import React from 'react'
import { Line } from 'react-chartjs-2'
import Skeleton from 'react-loading-skeleton'

import { ChartData, Chart as ChartJS, ChartOptions, registerables } from 'chart.js/auto'
import moment from 'moment'
import styled from 'styled-components'

import { formatMoney, isValidArray } from 'mmfintech-commons'
import {
  selectMerchantCurrency,
  useAppSelector,
  useCurrencies,
  useGetAggregatedBalanceQuery
} from 'mmfintech-backend-api'

import pattern from '../../../images/jetonbank-graph-pattern.svg'

const img = new Image()
img.src = pattern

const DashboardChart: React.FC = () => {
  const { getCurrencyPrecision } = useCurrencies()

  const { chartData, chartLabels, aggregatedBalanceFetching, aggregatedBalance } = useGetAggregatedBalanceQuery(
    {
      dateFrom: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD')
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        chartData: isValidArray(data) ? data.map(el => el.amount) : [],
        chartLabels: isValidArray(data) ? data.map(e => moment(e.date).format('D MMM')) : [],
        aggregatedBalanceFetching: isFetching,
        aggregatedBalance: data
      })
    }
  )
  const merchantCurrency = useAppSelector(selectMerchantCurrency)
  const isEmptyBalance = () => {
    const values = aggregatedBalance?.map(dayBalance => dayBalance.amount)
    return (
      Array.isArray(aggregatedBalance) &&
      values?.reduce((previousValue, currentValue) => previousValue + currentValue) === 0
    )
  }

  const prepareData = (): ChartData<'line'> => ({
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        fill: true,
        borderColor: '#0066FF',
        backgroundColor: context => {
          const { chart } = context
          const { ctx, chartArea } = chart
          if (!chartArea) {
            return
          }

          return ctx.createPattern(img, 'repeat')
        },
        borderWidth: 3,
        pointRadius: 4,
        hoverBackgroundColor: '#FFFFFF',
        hoverBorderColor: '#0066FF',
        hoverBorderWidth: 2,
        borderJoinStyle: 'round'
      }
    ]
  })

  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,

    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 5,
        bottom: 5
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#131E3D',
          font: {
            family: 'Roboto',
            size: 10,
            weight: 500,
            lineHeight: '14px'
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        max: isEmptyBalance() ? 5 : null,
        min: isEmptyBalance() ? 0 : null,
        ticks: {
          color: '#85A0AD',
          font: {
            family: 'Roboto',
            size: 10,
            weight: 500,
            lineHeight: '14px'
          },
          callback(value, index) {
            if (index === 0 || index % 2 === 0) {
              return formatMoney(value, merchantCurrency, getCurrencyPrecision(merchantCurrency))
            }
          }
        }
        // grid: {
        //   // borderDash: [3, 4],
        //   borderColor: 'transparent'
        // }
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
        position: 'average',
        caretPadding: 10,
        backgroundColor: '#FFFFFF',
        bodyColor: '#131E3D',
        padding: 15,
        bodyAlign: 'left',
        bodyFont: {
          family: 'Roboto',
          weight: 400,
          size: 16,
          lineHeight: '19px'
        },
        titleColor: '#7A7A7A',
        titleFont: {
          family: 'Roboto',
          weight: 400,
          size: 12,
          lineHeight: '17px'
        },
        callbacks: {
          title: context => context[0]?.label || 'Amount',
          label: context => {
            return formatMoney(context.raw, merchantCurrency, getCurrencyPrecision(merchantCurrency))
          }
        }
      },
      legend: {
        display: false
      }
    }
  }
  ChartJS.register(...registerables)
  return (
    <ChartWrapper>
      {aggregatedBalanceFetching ? (
        <Skeleton height={300} />
      ) : (
        <Line data={prepareData()} options={options} data-test='balance-chart' />
      )}
    </ChartWrapper>
  )
}

export default DashboardChart

const ChartWrapper = styled.div`
  height: 100%;
  max-height: 30rem;

  canvas {
    width: 100%;
    max-width: 100%;
    background: #fff;
    padding: 0 2rem 1rem 2rem;
    border-radius: 10px;
  }
`
