import { useMerchantAccounts, useMyPermissionsQry } from 'mmfintech-backend-api'

import { Preloader } from 'mmfintech-portal-commons'
import { AccountOverviewRow } from './AccountsOverviewRow'
import { AccountOverviewListWrapper } from './styled/accountOverview.styled'

export function AccountOverviewList() {
  const { permissions, permissionsFetching } = useMyPermissionsQry()
  const { accounts: paymentAccounts, accountsFetching } = useMerchantAccounts()

  if (accountsFetching || permissionsFetching) {
    return <Preloader />
  }

  const accounts = paymentAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <AccountOverviewListWrapper>
      {accounts.map((account: any, i: number) => (
        <AccountOverviewRow account={account} key={i} />
      ))}
    </AccountOverviewListWrapper>
  )
}
