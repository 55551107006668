import styled from 'styled-components'

import chevronRightImage from '../images/icons/arrow-right.svg'

export const ArrowRight = styled.span`
  float: right;
  display: flex;
  width: 5rem;
  height: 100%;
  border-radius: 0 3px 3px 0;
  //prettier-ignore
  background: url("${chevronRightImage}") center center no-repeat;
  visibility: hidden;

  .data-table-hover &:hover {
    visibility: visible;
  }
`
