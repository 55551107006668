import parse from 'html-react-parser'

import { isValidArray, isValidObject, tr } from 'mmfintech-commons'
import { useCountries, useGetCardBootstrapDataQuery } from 'mmfintech-backend-api'

import { ErrorDisplay, Input, Spinner } from 'mmfintech-portal-commons'
import { CardOrderFormStyled } from './CardOrderForm.styled'

import { CountrySupportedPurposeEnum, MerchantEntityTypeEnum } from 'mmfintech-commons-types'
import { useEffect } from 'react'

export const CardOrderForm = ({ formValues, cardType, entityType, setBootstrapError }) => {
  const { countryOptions } = useCountries(CountrySupportedPurposeEnum.REGISTRATION)
  const {
    data: bootstrapData,
    isLoading,
    error: bootstrapDataError
  } = useGetCardBootstrapDataQuery({ isVirtual: cardType === 'VIRTUAL' })

  useEffect(() => {
    if (formValues && bootstrapData) {
      Object.entries(bootstrapData).forEach(el => {
        const [key, value] = el
        if (!Array.isArray(value)) {
          formValues.setValue(key, value)
        }
        if (Array.isArray(value) && value.length === 1 && key === 'companyNameSuggestions') {
          formValues.setValue('companyName', value[0])
        }
      })
      if (bootstrapData.address) {
        Object.entries(bootstrapData.address).forEach(el => {
          const [key, value] = el
          if (key === 'addressLine1') {
            formValues.setValue('street', value)
          }
          formValues.setValue(key, value)
        })
      }
    }
  }, [bootstrapData])

  useEffect(() => {
    if (bootstrapDataError) {
      setBootstrapError(bootstrapDataError)
    }
  }, [bootstrapDataError])

  const prepareConsent = () => {
    const replaceUrl = (text: string, search: string, replacement: string): string => {
      const list = text.split(`<a data-type="${search}">`)
      return list.length > 1 ? list.join(`<a href="${replacement}" target='_blank' rel='noreferrer'>`) : text
    }

    const original = tr(
      'FRONTEND.CARDS.ORDER.DELIVERY.CONSENT',
      'By tapping Accept and complete order, you confirm that you have read and accept our <a data-type="terms-and-conditions">Terms and Conditions</a> and <a data-type="privacy-policy">Privacy Policy</a>'
    )

    return replaceUrl(
      replaceUrl(original, 'terms-and-conditions', 'https://www.jetonbank.com/terms-conditions/'),
      'privacy-policy',
      'https://www.jetonbank.com/privacy-policy/'
    )
  }

  console.log('formValues', formValues)

  if (isLoading) {
    return <Spinner />
  }

  return (
    <CardOrderFormStyled>
      {entityType === MerchantEntityTypeEnum.COMPANY && isValidObject(bootstrapData) && (
        <>
          <div className='card-order-form-title'>
            {tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.TITLE', 'Please provide your company details')}
          </div>
          <div className='card-order-form-inputs'>
            {isValidArray(bootstrapData?.companyNameSuggestions) &&
            bootstrapData?.companyNameSuggestions?.length == 1 ? (
              <Input
                type='static'
                label={tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.NAME', 'Company Name')}
                value={bootstrapData?.companyNameSuggestions?.[0]}
                autoComplete='off'
              />
            ) : (
              <Input
                type='select'
                label={tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.NAME', 'Company Name')}
                options={bootstrapData?.companyNameSuggestions?.map(el => {
                  return { label: el, value: el }
                })}
                {...formValues.registerInput('companyName')}
                autoComplete='off'
              />
            )}
          </div>
          {!bootstrapData?.firstName && (
            <Input
              type='text'
              label={tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.FIRST_NAME', 'First Name')}
              {...formValues.registerInput('firstName')}
              autoComplete='off'
            />
          )}
          {!bootstrapData?.lastName && (
            <Input
              type='text'
              label={tr('FRONTEND.CARDS.ORDER.COMPANY_DETAILS.LAST_NAME', 'Last Name')}
              {...formValues.registerInput('lastName')}
              autoComplete='off'
            />
          )}
        </>
      )}
      {cardType === 'PHYSICAL' && (
        <>
          <div className='card-order-form-title'>
            {tr(
              'FRONTEND.CARDS.ORDER.DELIVERY.TITLE',
              'Please provide the address you want your contactless debit card to be delivered'
            )}
          </div>

          <div className='card-order-form-inputs'>
            <Input
              type='select'
              label={tr('FRONTEND.CARDS.ORDER.DELIVERY.COUNTRY', 'Country')}
              options={countryOptions}
              {...formValues.registerInput('countryCode')}
              autoComplete='off'
            />
            <Input
              type='text'
              label={tr('FRONTEND.CARDS.ORDER.DELIVERY.CITY', 'City')}
              placeholder={tr('FRONTEND.CARDS.ORDER.DELIVERY.CITY_HINT', 'Please use only Latin letters')}
              {...formValues.registerInput('city')}
              autoComplete='off'
            />
            <Input
              type='text'
              label={tr('FRONTEND.CARDS.ORDER.DELIVERY.POST_CODE', 'Post code')}
              {...formValues.registerInput('postalCode')}
              autoComplete='off'
            />
            <Input
              type='text'
              label={tr('FRONTEND.CARDS.ORDER.DELIVERY.ADDRESS', 'Street name')}
              placeholder={tr('FRONTEND.CARDS.ORDER.DELIVERY.ADDRESS_HINT', 'Please use only Latin letters')}
              {...formValues.registerInput('street')}
              autoComplete='off'
            />
            <Input
              type='text'
              label={tr('FRONTEND.CARDS.ORDER.DELIVERY.STREET_NUMBER', 'Street number')}
              {...formValues.registerInput('streetNumber')}
              autoComplete='off'
            />
          </div>
        </>
      )}

      <div className='card-order-form-footer'>{parse(prepareConsent())}</div>
      <div className='error-wrapper'>
        <ErrorDisplay error={[bootstrapDataError]} />
      </div>
    </CardOrderFormStyled>
  )
}
