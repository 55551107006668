import { useHistory } from 'react-router-dom'

import fileDownload from 'js-file-download'

import { tr, fetchLastChunk, isValidString, formatMoney, cutString, formatDateTime } from 'mmfintech-commons'
import {
  endpoints,
  extractCounterpartyName,
  extractTransactionCustomerName,
  formatTransactionDescription,
  getTransactionStatusName,
  paths,
  useCurrencies,
  useFileDownloader
} from 'mmfintech-backend-api'
import { DataTable } from 'mmfintech-portal-commons'

import { breakpoints } from '../../../constants'
import StatusBanner from '../../../components/StatusBanner'
import { TransactionsMoreDropdown } from './TransactionsMoreDropdown'
import { TransactionsContainer } from './styled/transactionsTable.styled'

import { PaymentMethodEnum, TransactionViewPortal } from 'mmfintech-commons-types'

function TransactionsTable({ columns, transactions }) {
  const { getCurrencyPrecision } = useCurrencies()

  const history = useHistory()
  const downloader = useFileDownloader()

  const handleDetailsClick = (transactionId: number) => {
    history.push(paths.banking.transactions.details(transactionId), { origin: 'transactions' })
  }

  const handleTransactionReceiptClick = (transactionId: number) => {
    if (transactionId > 0 && !downloader.fetching) {
      void downloader.download({
        url: endpoints.transactions.getTransferReceipt(transactionId),
        method: 'GET',
        onSuccess: (data: any, filename?: string) => {
          fileDownload(data, filename || `transaction-receipt-${transactionId}.pdf`, 'application/pdf')
        }
      })
    }
  }

  return (
    <TransactionsContainer>
      <DataTable.Table scale2={`${breakpoints.xl}px`} skipHover>
        <DataTable.Head>
          <DataTable.Row>
            {columns.isVisible('id') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('foreignTransactionId') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Foreign ID')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('account') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}
              </DataTable.HeadCell>
            )}
            <DataTable.HeadCell className='no-border'>
              {tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
            </DataTable.HeadCell>
            {columns.isVisible('type') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.TRANSACTION_TYPE', 'Type')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('method') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment method')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('description') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('customer') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER', 'Counterparty')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('customerEmail') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_EMAIL', 'Customer email')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('customerName') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_NAME', 'Customer name')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('reference') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}
              </DataTable.HeadCell>
            )}
            <DataTable.HeadCell className='no-border'>
              {tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
            </DataTable.HeadCell>
            {columns.isVisible('processingAmount') && (
              <DataTable.HeadCell className='thin no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.PROCESSING_AMOUNT', 'Processing amount')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('status') && (
              <DataTable.HeadCell className='thin no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('failReason') && (
              <DataTable.HeadCell className='no-border'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS_DESCRIPTION', 'Fail reason')}
              </DataTable.HeadCell>
            )}
            <DataTable.HeadCell className='buttons no-border' />
          </DataTable.Row>
        </DataTable.Head>

        <DataTable.Body>
          {transactions.map((data: TransactionViewPortal, index: number) => {
            const {
              id,
              amount: transactionAmount,
              currency: transactionCurrency,
              foreignTransactionId,
              status,
              reqTime,
              direction,
              reference,
              accountName,
              paymentMethod,
              trnTypeLocalizationKey,
              statusCodeLocalizationKey,
              statusCodeDefaultDescription,
              processingAmount,
              processingCurrency,
              checkoutDetails,
              cardPaymentDetails
            } = data

            const { customerEmail } = checkoutDetails || {}
            const { transactionAmount: cardPaymentAmount, transactionCurrency: cardPaymentCurrency } =
              cardPaymentDetails || {}

            const amount = cardPaymentAmount ? cardPaymentAmount : transactionAmount
            const currency = cardPaymentAmount ? cardPaymentCurrency : transactionCurrency

            return (
              <DataTable.Row key={`data-${index}`} data-test='table-row'>
                {columns.isVisible('id') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}
                    data-test='id'>
                    {id}
                  </DataTable.Cell>
                )}
                {columns.isVisible('foreignTransactionId') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.FOREIGN_ID', 'Foreign ID')}
                    data-test='foreign-id'>
                    {foreignTransactionId}
                  </DataTable.Cell>
                )}
                {columns.isVisible('account') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ACCOUNT', 'Account')}
                    data-test='account'>
                    {accountName}
                  </DataTable.Cell>
                )}
                <DataTable.Cell
                  caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
                  className='no-wrap no-border'
                  data-test='date'>
                  {formatDateTime(reqTime)}
                </DataTable.Cell>
                {columns.isVisible('type') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TRANSACTION_TYPE', 'Type')}
                    data-test='type'>
                    {tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                  </DataTable.Cell>
                )}
                {columns.isVisible('method') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.PAYMENT_METHOD', 'Payment Method')}
                    data-test='payment-method'>
                    {tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
                  </DataTable.Cell>
                )}
                {columns.isVisible('description') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                    data-test='description'>
                    {formatTransactionDescription(data)}
                  </DataTable.Cell>
                )}
                {columns.isVisible('customer') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER', 'Counterparty')}
                    data-test='counterparty'>
                    {extractCounterpartyName(data)}
                  </DataTable.Cell>
                )}
                {columns.isVisible('customerEmail') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_EMAIL', 'Customer email')}
                    data-test='customer-email'>
                    {customerEmail}
                  </DataTable.Cell>
                )}
                {columns.isVisible('customerName') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMER_NAME', 'Customer name')}
                    data-test='customer-name'>
                    {extractTransactionCustomerName(data)}
                  </DataTable.Cell>
                )}
                {columns.isVisible('reference') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.REFERENCE', 'Reference')}
                    data-test='reference'>
                    {cutString(reference, 50)}
                  </DataTable.Cell>
                )}
                <DataTable.Cell
                  className='no-border'
                  caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
                  data-test='amount'>
                  <span className='amount text-nowrap'>
                    {formatMoney(
                      amount * (direction === 'WITHDRAW' ? -1 : 1),
                      currency,
                      getCurrencyPrecision(currency)
                    )}
                  </span>
                </DataTable.Cell>
                {columns.isVisible('processingAmount') && (
                  <DataTable.Cell
                    className='no-border'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.PROCESSING_AMOUNT', 'Processing amount')}
                    data-test='processing-amount'>
                    <span className='amount text-nowrap'>
                      {formatMoney(
                        processingAmount * (direction === 'WITHDRAW' ? -1 : 1),
                        processingCurrency,
                        getCurrencyPrecision(processingCurrency)
                      )}
                    </span>
                  </DataTable.Cell>
                )}

                {columns.isVisible('status') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                    className='status-cell no-border'
                    data-test='status'>
                    <StatusBanner status={getTransactionStatusName(status)} />
                  </DataTable.Cell>
                )}
                {columns.isVisible('failReason') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS_DESCRIPTION', 'Fail reason')}
                    className='no-border'
                    data-test='fail-reason'>
                    {isValidString(statusCodeLocalizationKey)
                      ? tr(statusCodeLocalizationKey, statusCodeDefaultDescription)
                      : statusCodeDefaultDescription}
                  </DataTable.Cell>
                )}
                <DataTable.Cell className='no-border'>
                  <TransactionsMoreDropdown
                    handleDetailsClick={() => handleDetailsClick(id)}
                    handleTransactionReceiptClick={
                      paymentMethod === PaymentMethodEnum.BANKWIRE || paymentMethod === PaymentMethodEnum.SWIFT_BANKWIRE
                        ? () => handleTransactionReceiptClick(id)
                        : null
                    }
                  />
                </DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable.Body>
      </DataTable.Table>
    </TransactionsContainer>
  )
}

export default TransactionsTable
