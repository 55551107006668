import { useContext, useMemo } from 'react'

import { GlobalContext, tr } from 'mmfintech-commons'
import { useMerchantAccounts } from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import { ButtonsWrapper } from './TransactionsExportButtons.styled'
import { exportTypes, TransactionsExportModal } from './TransactionsExportModal'

import ExportIcon from '@images/icons/transactions-export-document.svg?react'

interface TransactionsExportButtonsInterface {
  accountId: number
  from: Date
  to: Date
  filter: any
}

export const TransactionsExportButtons = ({ accountId, from, to, filter }: TransactionsExportButtonsInterface) => {
  const { modalShow } = useContext(GlobalContext)

  const { accounts } = useMerchantAccounts()

  const selectedAccount = useMemo(
    () => (Array.isArray(accounts) && accountId ? accounts.find(account => account.id === accountId) : null),
    [accounts, accountId]
  )

  const handleExport = (type: exportTypes) => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: false, closeOnEscape: false },
      content: (
        <TransactionsExportModal
          filter={filter}
          type={type}
          account={selectedAccount}
          accounts={accounts}
          dateFrom={from}
          dateTo={to}
        />
      )
    })
  }

  return (
    <ButtonsWrapper>
      <Button
        color='round-primary'
        icon={<ExportIcon />}
        text={tr('FRONTEND.TRANSACTIONS.BALANCE_STATEMENT_BUTTON', 'Balance Statement PDF')}
        onClick={() => handleExport(exportTypes.StatementPDF)}
        data-test='button-balance-statement'
      />
      <Button
        color='round-primary'
        icon={<ExportIcon />}
        text={tr('FRONTEND.TRANSACTIONS.EXPORT_BALANCE_CSV', 'Balance statement CSV')}
        onClick={() => handleExport(exportTypes.StatementSCV)}
        data-test='button-export-csv'
      />
      <Button
        color='round-primary'
        icon={<ExportIcon />}
        text={tr('FRONTEND.TRANSACTIONS.EXPORT_TRANSACTION_CSV', 'Transaction CSV')}
        onClick={() => handleExport(exportTypes.TransactionsSCV)}
        data-test='button-export-csv'
      />
    </ButtonsWrapper>
  )
}
