import { useDispatch } from 'react-redux'
import { useState } from 'react'

import { resetEmailVerification, useChangeTwoFactorMutation } from 'mmfintech-backend-api'
import { LegalRequirementResponse } from 'mmfintech-commons-types'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import { LegalRequirementsModal, Stepper, GoogleAuthenticatorInfo } from '../../../../components'
import useOnboarding from '../../../../views/onboarding/useOnboarding'
import { Success2Fa, WelcomeContent2 } from './WelcomeContent2'

import { ModalContainer, WelcomeModalWrapper } from './WelcomeModal.styled'

interface WelcomeModal {
  requirements?: LegalRequirementResponse[]
  currentStep?: number
  isSuccess2Fa?: boolean
}

const MAX_STEPS = [
  { step: 1, label: '' },
  { step: 2, label: '' },
  { step: 3, label: '' }
]

export const WelcomeModal = ({ requirements, currentStep, isSuccess2Fa }: WelcomeModal) => {
  const [change2fa, { error: change2faError }] = useChangeTwoFactorMutation()
  const [step, setStep] = useState(currentStep || 1)
  const { startOrContinueOnboarding } = useOnboarding()
  const dispatch = useDispatch()

  const handleSubmit = e => {
    if (e) e.preventDefault()

    change2fa({
      twoFactor: 'TOTP'
    })
    dispatch(resetEmailVerification())
  }

  return (
    <ModalContainer>
      <WelcomeModalWrapper data-test='welcome-modal'>
        <div className='welcome-modal-content'>
          {step === 3 && isSuccess2Fa && <Success2Fa isSuccess2Fa={isSuccess2Fa} />}

          <h1 className='title-welcome '>{tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to Jetonbank!')}</h1>

          <Stepper className='stepper' currentStep={step} maxStep={MAX_STEPS} />

          {step === 1 && (
            <LegalRequirementsModal isInModal={true} requirements={requirements} onAccepted={() => setStep(2)} />
          )}
          {step === 2 && <GoogleAuthenticatorInfo />}
          {step === 3 && <WelcomeContent2 isSuccess2Fa={isSuccess2Fa} />}

          <ErrorDisplay error={change2faError} />

          {step === 2 && (
            <div className='buttons-wrapper'>
              <Button
                text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.ENABLE', 'Enable now')}
                color='primary'
                onClick={handleSubmit}
              />
              <Button
                text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.SKIP', 'Skip for now')}
                color='secondary'
                onClick={() => {
                  setStep(3)
                }}
              />
            </div>
          )}
          {step === 3 && (
            <div className='buttons-wrapper'>
              <Button
                text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.BUTTON', 'Get Started')}
                color='primary'
                onClick={() => {
                  startOrContinueOnboarding()
                  dispatch(resetEmailVerification())
                }}
              />
            </div>
          )}
        </div>
      </WelcomeModalWrapper>
    </ModalContainer>
  )
}
