import { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import moment from 'moment'

import { fixDateOnly, formatDate, GlobalContext, isValidArray, todayDate } from 'mmfintech-commons'
import {
  paths,
  selectCurrentLanguage,
  transformMerchantActivity,
  useActivitiesQry,
  useAppSelector,
  useLazyGetMerchantActivitiesQuery,
  useLazyPreviewInvoiceQuery
} from 'mmfintech-backend-api'
import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import InvoicePreview from './InvoicePreview'

import {
  ActivitiesMenuWrapper,
  ActivityColumn,
  ActivityWrapper,
  CategoryTitle,
  DateWrapper
} from './styled/activitiesMenu.styled'

import { MerchantActivityOut } from 'mmfintech-commons-types'

import ExchangeIcon from '../images/icons/exchange-icon.svg?react'
import CalendarIcon from '../images/icons/filter-calendar.svg?react'
import CloseIcon from '../images/icons/icon-close-transparent.svg?react'
import InvoicesIcon from '../images/icons/invoices-icon.svg?react'
import NoActivitiesIcon from '../images/icons/no-transactions.svg?react'
import OthersIcon from '../images/icons/others-icon.svg?react'
import RecipientsIcon from '../images/icons/recipients-icon.svg?react'
import TransactionsIcon from '../images/icons/transactions-icon.svg?react'
import UsersIcon from '../images/icons/users-icon.svg?react'

enum Category {
  Transactions,
  Exchange,
  Recipients,
  Users,
  Invoices,
  Others
}
type ActivityCategories = {
  [K in keyof typeof Category]: string[]
}
type ActivityPaletteProps = {
  [K in keyof typeof Category]: {
    icon: JSX.Element
    textColor: `#${string}`
  }
}

type ActivityComponentProps = {
  category: Category
  activities: MerchantActivityOut[]
}

function groupByAndArrange(
  list: MerchantActivityOut[],
  keyGetter: (item: MerchantActivityOut) => MerchantActivityOut['type']
) {
  const map = new Map()
  isValidArray(list) &&
    list?.forEach((item: MerchantActivityOut) => {
      let type = (Object.keys(activityCategories) as (keyof typeof activityCategories)[]).find(key =>
        activityCategories[key].includes(keyGetter(item))
      )
      if (!type) {
        type = 'Others'
      }
      const collection = map.get(type)
      if (!collection) {
        map.set(type, [item])
      } else {
        collection.push(item)
      }
    })
  return map.size > 0 ? Array.from(map) : []
}

const ActivitiesMenu = ({ onClose }: { onClose: () => void }) => {
  const selectedLanguage = useAppSelector(selectCurrentLanguage)

  const [fetchActivities, { lastActivities, lastActivitiesError, lastActivitiesFetching }] =
    useLazyGetMerchantActivitiesQuery({
      selectFromResult: ({ data, error, isFetching }) => ({
        lastActivities: isValidArray(data?.content) ? data.content : [],
        lastActivitiesError: error,
        lastActivitiesFetching: isFetching
      })
    })

  const arrangedActivities = groupByAndArrange(lastActivities, (line: any) => line.type)

  const [filter, setFilter] = useState(new Date())

  useEffect(() => {
    if (filter) {
      fetchActivities({
        from: fixDateOnly(filter),
        to: fixDateOnly(filter),
        page: 0,
        size: 10
      })
    }
  }, [filter])

  return (
    <ActivitiesMenuWrapper>
      <div className='top-section'>
        <h3>Activity</h3>
        <CloseIcon onClick={onClose} />
      </div>
      <DatePicker
        locale={selectedLanguage}
        maxDate={todayDate()}
        selected={filter}
        onChange={setFilter}
        calendarStartDay={1}
        showPopperArrow={false}
        customInput={
          <DateWrapper className='borderless'>
            <CalendarIcon />
            <span>{formatDate(filter)}</span>
          </DateWrapper>
        }
      />

      <ErrorDisplay error={lastActivitiesError} />

      <div className='activity-list'>
        {lastActivitiesFetching ? (
          <Preloader />
        ) : isValidArray(arrangedActivities) ? (
          arrangedActivities.map(([category, activities], index) => (
            <ActivityComponent key={index} activities={activities} category={category} />
          ))
        ) : (
          <div className='no-activities'>
            <NoActivitiesIcon fill='black' />
            No activities for the selected period.
          </div>
        )}
      </div>
    </ActivitiesMenuWrapper>
  )
}

export default ActivitiesMenu

const ActivityComponent = ({ category, activities }: ActivityComponentProps) => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const { handleActivityClick } = useActivitiesQry({
    securityPath: paths.security(),
    handleInvoicePreview: invoiceId => handlePreview(invoiceId)
  })

  const [fetchInvoicePreview] = useLazyPreviewInvoiceQuery()

  const handlePreview = (invoiceId: string | number) => {
    fetchInvoicePreview({ invoiceId })
      .unwrap()
      .then((response: any) => {
        modalShow({
          options: { size: 'extra-large' },
          content: <InvoicePreview content={response} onClose={modalHide} />
        })
      })
      .catch(() => {
        return
      })
  }

  const { icon, textColor } = activityPalette[category]

  return (
    <ActivityColumn>
      <div className='column-header'>
        {icon}
        <CategoryTitle color={textColor}>{category}</CategoryTitle>
      </div>
      {isValidArray(activities) &&
        activities.map((activity: MerchantActivityOut, index) => {
          const { text, time } = activity
          const data = transformMerchantActivity(activity)
          return (
            <ActivityWrapper
              key={`${text} - ${index}`}
              onClick={() => {
                handleActivityClick(data)
              }}>
              <div className='timestamp'>{moment(time).format('hh:mm')}</div>
              <div className='activity-title'>{text.replace(/<.*?>/g, '')}</div>
            </ActivityWrapper>
          )
        })}
    </ActivityColumn>
  )
}

const activityCategories: ActivityCategories = {
  Transactions: [
    'DEPOSIT_PENDING',
    'DEPOSIT_PROCESSED',
    'DEPOSIT_REJECTED',
    'DEPOSIT_RETURNED',
    'TRANSFER_RECEIVED',
    'TRANSFER_SENT',
    'WALLET_PAYMENT_COMPLETED',
    'WITHDRAWAL_PENDING',
    'WITHDRAWAL_PROCESSED',
    'WITHDRAWAL_REJECTED',
    'WITHDRAWAL_REVERTED'
  ],
  Exchange: ['EXCHANGE_PROCESSED'],
  Recipients: ['CONTACT_CREATED', 'CUSTOMER_CREATED'],
  Users: [
    'ACCOUNT_APPROVED',
    'ISSUE_IBAN_DECLINED',
    'ISSUE_IBAN_FAILED',
    'ISSUE_IBAN_INITIATED',
    'ISSUE_IBAN_ISSUED',
    'REFUND_PENDING',
    'REFUND_PROCESSED',
    'REFUND_REJECTED',
    'REGISTERED_ACCOUNT'
  ],
  Invoices: [
    'ENOTAS_INVOICE_APPROVED',
    'ENOTAS_INVOICE_CANCELED',
    'ENOTAS_INVOICE_DECLINED',
    'INVOICE_CREATED',
    'INVOICE_OPENED',
    'INVOICE_PAID',
    'INVOICE_PARTIALLY_PAID'
  ],
  Others: []
}

const activityPalette: ActivityPaletteProps = {
  Transactions: { icon: <TransactionsIcon />, textColor: '#3CD1A3' },
  Exchange: { icon: <ExchangeIcon />, textColor: '#699CFC' },
  Recipients: { icon: <RecipientsIcon />, textColor: '#F5A100' },
  Users: { icon: <UsersIcon />, textColor: '#B46BFD' },
  Invoices: { icon: <InvoicesIcon />, textColor: '#F73B3B' },
  Others: { icon: <OthersIcon />, textColor: '#C2C9D6' }
}
