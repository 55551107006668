import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import {
  formatTransactionDescription,
  getTransactionStatusName,
  useCurrencies,
  useLazyGetTransactionsQuery
} from 'mmfintech-backend-api'
import { fetchLastChunk, formatDateTime, formatMoney, isValidArray, tr, usePaginationQuery } from 'mmfintech-commons'
import { DataTable, ErrorDisplay, NoElementsMessage, Pagination } from 'mmfintech-portal-commons'

import { breakpoints } from '../../constants'
import StatusBanner from '../../components/StatusBanner'
import {
  TransactionsTableContainer,
  TransactionsWrapper
} from '../dashboard/elements/styled/transactionsSection.styled'
import { TransactionViewPortal } from 'mmfintech-commons-types'

function TransactionsTable({ filter, showPagination, forceReload }) {
  const { getCurrencyPrecision } = useCurrencies()
  const [trigger, { data: transactionsResponse, error: transactionsError, isFetching: transactionsFetching }] =
    useLazyGetTransactionsQuery()

  const pagination = usePaginationQuery({
    rowsPerPage: 5,
    reload: async (params = {}, onSuccess: (response: any) => void) => {
      const transactionFilter = {
        ...filter,
        ...params
      }
      try {
        const response = await trigger(transactionFilter).unwrap()
        onSuccess(response)
      } catch {}
    }
  })

  useEffect(() => {
    if (forceReload) {
      pagination.reload()
    }

    // eslint-disable-next-line
  }, [forceReload])

  return (
    <TransactionsWrapper>
      {transactionsFetching ? (
        <Skeleton count={5} height={50} />
      ) : transactionsError ? (
        <ErrorDisplay error={transactionsError} />
      ) : (
        <TransactionsTableContainer>
          {isValidArray(transactionsResponse?.content) ? (
            <DataTable.Table scale2={`${breakpoints.xl}px`} skipHover>
              <DataTable.Head>
                <DataTable.Row>
                  {/* <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}</DataTable.HeadCell> */}
                  <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}</DataTable.HeadCell>
                  <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}</DataTable.HeadCell>
                  <DataTable.HeadCell>
                    {tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}</DataTable.HeadCell>
                  <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}</DataTable.HeadCell>
                </DataTable.Row>
              </DataTable.Head>

              <DataTable.Body>
                {transactionsResponse.content.map((data: TransactionViewPortal) => {
                  const {
                    id,
                    reqTime,
                    amount: transactionAmount,
                    currency: transactionCurrency,
                    direction,
                    status,
                    cardPaymentDetails,
                    trnTypeLocalizationKey
                  } = data
                  const { transactionAmount: cardPaymentAmount, transactionCurrency: cardPaymentCurrency } =
                    cardPaymentDetails || {}

                  const amount = cardPaymentAmount ? cardPaymentAmount : transactionAmount
                  const currency = cardPaymentAmount ? cardPaymentCurrency : transactionCurrency

                  return (
                    <DataTable.Row key={id} data-test='table-row'>
                      {/* <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')} data-test='id'>
                          {id}
                        </DataTable.Cell> */}

                      <DataTable.Cell
                        caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
                        className='no-wrap no-border'
                        data-test='date'>
                        {formatDateTime(reqTime)}
                      </DataTable.Cell>

                      <DataTable.Cell
                        className='no-border'
                        caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}
                        data-test='type'>
                        {tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                      </DataTable.Cell>

                      <DataTable.Cell
                        className='no-border'
                        caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                        data-test='description'>
                        {formatTransactionDescription(data)}
                      </DataTable.Cell>

                      <DataTable.Cell
                        caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                        className={`text-upper status-${status.toLowerCase()} no-border`}
                        data-test='status'>
                        <StatusBanner status={getTransactionStatusName(status)} />
                      </DataTable.Cell>

                      <DataTable.Cell
                        className='no-border'
                        caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
                        data-test='amount'>
                        {formatMoney(
                          amount * (direction === 'WITHDRAW' ? -1 : 1) || 0,
                          currency || '',
                          getCurrencyPrecision(currency)
                        )}
                      </DataTable.Cell>
                    </DataTable.Row>
                  )
                })}
                {showPagination && (
                  <DataTable.Row>
                    <DataTable.Cell colSpan={6} className='pagination-cell'>
                      <Pagination {...pagination.register()} />
                    </DataTable.Cell>
                  </DataTable.Row>
                )}
              </DataTable.Body>
            </DataTable.Table>
          ) : (
            <NoElementsMessage
              message={tr('FRONTEND.DASHBOARD.NO_TRANSACTIONS', "You don't have any transactions yet")}
            />
          )}
        </TransactionsTableContainer>
      )}
    </TransactionsWrapper>
  )
}

export default TransactionsTable
