import { useContext, useState } from 'react'

import settings from '../../settings'
import { configuration } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'

import { CloseIcon } from '../../components'
import { Button, SwitchInput } from 'mmfintech-portal-commons'
import {
  ManageCookiesTitleWrapper,
  ManageCookiesWrapper,
  NecessaryCookiesWrapper,
  OptionalCookiesWrapper,
  SaveButtonWrapper
} from './styled/manageCookies.styled'

import cookieImage from '../../images/icons/cookie.svg'

function ManageCookies({ setCookie }) {
  const { modalHide } = useContext(GlobalContext)

  const [optionalCookies, setOptionalCookies] = useState(true)

  return (
    <ManageCookiesWrapper data-test='manage-cookies-modal'>
      <CloseIcon onClick={modalHide} />

      <ManageCookiesTitleWrapper>
        <div>
          <img src={cookieImage} alt='Cookie' />
        </div>
        <div>
          <h1>{tr('FRONTEND.COOKIE_CONSENT.ST_COOKIE_POLICY', 'Jetonbank Cookies Policy')}</h1>
        </div>
      </ManageCookiesTitleWrapper>

      <NecessaryCookiesWrapper>
        <div>
          <h2 className='title'>{tr('FRONTEND.COOKIE_CONSENT.NEED_COOKIES_TITLE', 'Necessary Cookies')}</h2>
          <p>
            {tr(
              'FRONTEND.COOKIE_CONSENT.NEED_COOKIES',
              'We need to use these cookies to make our website work. These are all necessary for us to function and cannot be switched off in our systems. They are for setting responses to actions made by you, such as setting your privacy preferences, logging in or filling forms.'
            )}
          </p>
        </div>
        <div>
          <SwitchInput disabled checked />
        </div>
      </NecessaryCookiesWrapper>

      <OptionalCookiesWrapper>
        <div>
          <h2>{tr('FRONTEND.COOKIE_CONSENT.ST_COOKIE_POLICY', 'Jetonbank Cookies Policy')}</h2>
          <p>
            {tr(
              'FRONTEND.COOKIE_CONSENT.COUNT_VISITS',
              'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. All information that our cookies collect is aggregated and anonymous.'
            )}
          </p>
          <p>
            {tr(
              'FRONTEND.COOKIE_CONSENT.SEE_PAGES_AND_LINKS',
              "These also allow us to see the pages and links you have visited so we can prevent relevant ads. We don't store directly personal information but identify your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising."
            )}
          </p>
        </div>

        <div>
          <SwitchInput checked={optionalCookies} onChange={e => setOptionalCookies(e.target.checked)} />
        </div>
      </OptionalCookiesWrapper>

      <SaveButtonWrapper>
        <Button
          type='button'
          className='btn-consent'
          text={tr('FRONTEND.COOKIE_CONSENT.SAVE', 'Save')}
          onClick={() => {
            setCookie(
              'cookie.consent',
              JSON.stringify({
                necessaryCookies: true,
                optionalCookies
              }),
              {
                days: 365,
                maxAge: 31536000,
                domain: configuration.isLocal() ? null : settings.cookieDomain
              }
            )

            modalHide()
          }}
          data-test='button-save'
        />
      </SaveButtonWrapper>
    </ManageCookiesWrapper>
  )
}

export default ManageCookies
