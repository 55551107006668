import { tr } from 'mmfintech-commons'

import SuccessIcon from '../../images/icons/card-success-icon.svg?react'
import { CardOrderSuccessStyled } from './CardOrderSuccess.styled'

export const CardOrderSuccess = ({ type }) => (
  <CardOrderSuccessStyled>
    <SuccessIcon />

    <div>
      <div className='card-order-success-title'>
        {tr('FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.TITLE', 'You have successfully ordered Jetonbank Card!')}
      </div>
      <div className='card-order-success-subtitle'>
        {type == 'physical'
          ? tr(
              'FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.SUBTITLE',
              'Your card will be ready to be delivered within 1-2 weeks.'
            )
          : tr('FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.VIRTUAL', 'Your card will be available in the Cards menu.')}
      </div>
    </div>
  </CardOrderSuccessStyled>
)
