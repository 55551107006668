import styled from 'styled-components'

import CheckedIcon from '../../images/icons/checkbox-group-checked.svg'
import EmptyIcon from '../../images/icons/checkbox-group-empty.svg'

export const CheckBoxGroupWrapper = styled.div`
  width: 100%;

  color: #1a1a1a;
  font-family: inherit;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  .item-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
  }
`

export const CheckBoxGroupFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 2rem;
`

export const CheckBoxGroupHeader = styled.div`
  font-weight: 500;
  margin-bottom: 2rem;
`

export const CheckBoxGroupItem = styled.div`
  border-radius: 10px;
  background: #ffffff;
  padding: 1.7rem;

  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  white-space: nowrap;

  .checkbox {
    cursor: pointer;
    display: inline-block;

    width: 2rem;
    height: 2rem;

    //prettier-ignore
    background-image: url("${EmptyIcon}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.checked {
      //prettier-ignore
      background-image: url("${CheckedIcon}");
    }
  }
`
