import { useContext, useState } from 'react'
import toast from 'react-hot-toast'

import styled from 'styled-components'

import {
  selectMerchantCurrency,
  useAppSelector,
  useMerchantAccounts,
  useSetExpenseLimitMutation
} from 'mmfintech-backend-api'
import { GlobalContext, getCurrencySymbol, tr, isValidArray, fixFloat } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import InputAmount from '../../components/InputAmount'
import { LimitInput } from '../users/elements/styled/CustomizeUserSettings.styled'

type ChangeLimitModalProps = {
  merchantUserId: number
  merchantUserName: string
  currency: string
  monthlyVolume: number | string
}

function ChangeLimitModal({ merchantUserId, merchantUserName, currency, monthlyVolume }: ChangeLimitModalProps) {
  const { modalHide }: any = useContext(GlobalContext)

  const [updatedMonthlyVolume, setUpdatedMonthlyVolume] = useState(monthlyVolume)

  const merchantCurrency = useAppSelector(selectMerchantCurrency)
  const { activeAccounts } = useMerchantAccounts()

  const [changeLimit] = useSetExpenseLimitMutation()

  const onSuccess = () => {
    modalHide()
    toast.success(
      tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.LIMITS.CHANGE_LIMIT.SUCCESS_TOAST', 'Successfully updated limit')
    )
  }

  const handleSubmit = async () => {
    if (updatedMonthlyVolume) {
      try {
        await changeLimit({
          monthlyVolume: fixFloat(updatedMonthlyVolume),
          merchantUserId
        }).unwrap()
        onSuccess()
      } catch (error) {}
    }
  }

  return (
    <ChangeLimitModalWrapper data-test='change-limit-modal'>
      <ChangeLimitInner>
        <div className='title'>{tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.LIMITS.CHANGE_LIMIT.TITLE', 'Change Limit')}</div>
        <div className='change-limit-text'>
          {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.LIMITS.CHANGE_LIMIT.BODY', 'Change the monthly limit of user:')}{' '}
          <span>{merchantUserName}</span>
        </div>
        <LimitInput>
          <InputAmount
            dataTest='monthly-limit-input'
            label={tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.LIMITS.INPUT_TITLE', 'Set Monthly Limit')}
            placeholder={getCurrencySymbol(currency)}
            amount={updatedMonthlyVolume.toString()}
            setAmount={value => setUpdatedMonthlyVolume(value)}
            account={
              isValidArray(activeAccounts)
                ? activeAccounts.filter(
                    (acc: any) => acc.currencyCode === merchantCurrency && acc.type === 'PRIMARY'
                  )[0]
                : {}
            }
          />
        </LimitInput>
        <Button
          text={tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.LIMITS.SET', 'Set')}
          disabled={!updatedMonthlyVolume}
          color='primary'
          onClick={handleSubmit}
        />
        <Button text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')} color='secondary' onClick={modalHide} />
      </ChangeLimitInner>
    </ChangeLimitModalWrapper>
  )
}

export default ChangeLimitModal

const ChangeLimitInner = styled.div`
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  .title {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.9rem;

    margin: 2rem 0;
  }
  .change-limit-text {
    color: #333333;

    font-size: 1.5rem;
    margin: 0 0 4rem 0;
    span {
      color: #131e3d;
      font-weight: 500;
    }
  }
  .button {
    align-self: center;
    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
`

const ChangeLimitModalWrapper = styled.div`
  color: #131e3d;
  width: 100%;
  min-width: 50rem;
  @media (max-width: 565px) {
    max-width: 35rem;
    min-width: 35rem;
    padding: 1rem;
  }

  padding: 4rem 5rem;
`
