import styled from 'styled-components'

export const CardOrderFormStyled = styled.div`
  color: var(--primary-text-color);

  .card-order-form-title {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 3rem;
    text-align: center;
  }

  .core-input-wrapper,
  .card-order-form-name-title {
    .core-input-section {
      height: 5.6rem;
    }
  }

  .selection-element-wrapper {
    min-height: 5.6rem;
  }

  .card-order-form-footer {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0.8rem;
    text-align: center;

    a {
      text-decoration: none;
      outline: none;
      color: #06f;
    }
  }

  .card-order-form-inputs {
    display: flex;
    flex-direction: column;
    //gap: 1.6rem;
    //padding-bottom: 3.2rem;
    //border-bottom: 1px solid var(--card-order-modal-background-color);
  }

  .card-order-form-name {
    width: 100%;
    display: flex;
    gap: 1.6rem;

    .card-order-form-name-title {
      width: 12rem;
    }

    .card-order-form-name-value {
      flex: 1;
    }
  }

  .card-order-form-surname {
    margin-bottom: 2.4rem;
  }

  .error-wrapper {
    margin: 3rem 0;
  }
`
