import styled from 'styled-components'

export const CardOrderSuccessStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;

  padding: 2.4rem 0;
  text-align: center;

  .card-order-success-title {
    color: rgba(19, 30, 61, 1);
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
  }

  .card-order-success-subtitle {
    color: black;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
  }

  img {
    width: 20rem;
    padding: 3.2rem 0 6.4rem;
  }
`
