import styled from 'styled-components'

export const DetailsForm = styled.div`
  grid-template-columns: 1fr;
  column-gap: 3rem;
  display: grid;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);

    .double {
      grid-column: 1 / span 2;
    }
  }

  .input-wrapper {
    margin-bottom: 2rem;
  }
`

export const CardIssuingCapability = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  
  color: black;
  font-weight: 400;
`

export const AccountForm = styled.div`
  .userTableHeaderRow {
    background-color: #ffff;
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0 1px rgba(163, 184, 194, 0.3);
  }

  .data-table-row {
    border: none !important;
  }

  .checkbox {
    text-align: center;
  }
  .checkbox-icon {
    width: 2rem !important;
    height: 2rem !important;
  }
  .checked.alternative {
    .checkbox-icon {
      background-color: #00b451;
      border-color: #00b451;
    }
  }
  .scrollable {
    overflow-y: scroll;
    height: 220px;
    display: block;
    margin-bottom: 5rem;
    margin-top: 3rem;
  }
`
export const LimitInput = styled.div``
