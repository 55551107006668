import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import toast from 'react-hot-toast'
import ReactTooltip from 'react-tooltip'

import { breakpoints } from '../../constants'
import { GlobalContext, isValidArray, tr, useWindowSize } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  selectMerchantCapabilities,
  useAccountListQry,
  useAppSelector,
  useMerchantAccounts
} from 'mmfintech-backend-api'

import AccountEditModal from './AccountEditModal'
import AccountCreateModal from './AccountCreateModal'

import { SelectInput } from '../../components/forms'
import { AccountDataRow } from './AccountTableRow'
import { Button, DataTable, ErrorDisplay, Pagination, Preloader } from 'mmfintech-portal-commons'
import { CommonPageTitle, RowsMainSection, RowsTopSection, RowsTwoSectionContainer } from '../../components/jetonStyled'
import {
  AccountHeader,
  AccountsContainer,
  AccountsMain,
  CurrenciesSelectStyled,
  TitleContainer
} from './styled/accounts.styled'

import NewInvoiceIcon from '../../images/icons/new-icon.svg?react'
import RequestIcon from '../../images/icons/request-icon.svg?react'

function Accounts() {
  const { modalShow } = useContext(GlobalContext)

  const [filteredCurrencies, setFilteredCurrencies] = useState([])

  const customerRole = useAppSelector(selectCurrentUserRole)
  const capabilities = useAppSelector(selectMerchantCapabilities)
  const { disableCurrencyAccountCreation } = capabilities || {}

  const { accounts: paymentAccounts } = useMerchantAccounts()

  const history = useHistory()

  const [width] = useWindowSize()

  const { accounts, accountsError, accountsFetching, pagination, selectedCurrency, setSelectedCurrency } =
    useAccountListQry()

  const handleNewAccountClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto', overflow: 'none' },
      content: (
        <AccountCreateModal
          onSuccess={(currencyCode: string) => {
            pagination.reload()
            toast.remove()
            toast.success(
              tr('FRONTEND.ACCOUNTS.SUCCESS_MESSAGE', '%CURRENCY% Account successfully added').replace(
                '%CURRENCY%',
                currencyCode
              )
            )
          }}
        />
      )
    })
  }

  const handleIbanRequestClick = () => history.push(paths.ibanIssuing())

  const handleEditAccountClick = (account: any) => {
    modalShow({
      options: {
        closeOnClickOutside: false,
        size: 'auto',
        overflow: 'none'
      },
      content: (
        <AccountEditModal
          account={account}
          onSuccess={() => {
            pagination.reload({
              page: pagination.currentPage
            })
          }}
        />
      )
    })
  }

  useEffect(() => {
    const all = [{ value: 'all', label: tr('FRONTEND.ACCOUNTS.ALL_CURRENCIES', 'All Currencies') }]
    if (isValidArray(paymentAccounts)) {
      const currencies = paymentAccounts
        .map(account => account.currencyCode)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
      setFilteredCurrencies([...all, ...currencies.map(c => ({ value: c, label: c }))])
    } else {
      setFilteredCurrencies(all)
    }
  }, [paymentAccounts])

  useEffect(() => {
    pagination.reload()
  }, [selectedCurrency])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <RowsTwoSectionContainer size='lg'>
      <RowsTopSection>
        <TitleContainer>
          <span />
          <div className='top-section'>
            {width >= breakpoints.xl && (
              <CommonPageTitle className='title'>{tr('FRONTEND.ACCOUNTS.TITLE', 'Accounts')}</CommonPageTitle>
            )}
            <div className='currencySelector'>
              <SelectInput
                name='search-option'
                data-test='select-currency-dropdown'
                value={selectedCurrency}
                onChange={(_name, value) => {
                  setSelectedCurrency(value)
                }}
                options={filteredCurrencies}
                applyStyles={CurrenciesSelectStyled}
                disableSearch
                hideLabel
                hideRequired
                hideErrorLine
              />
            </div>
          </div>
        </TitleContainer>
      </RowsTopSection>
      <RowsMainSection>
        <AccountsMain>
          <AccountHeader>
            {isOwnerOrAdministrator(customerRole) && (
              <>
                {!disableCurrencyAccountCreation ? (
                  <Button
                    icon={<NewInvoiceIcon />}
                    color='round-secondary'
                    text={tr('FRONTEND.ACCOUNTS.NEW_ACCOUNT_BUTTON', 'New Account')}
                    onClick={handleNewAccountClick}
                    data-test='button-new-account'
                  />
                ) : null}
                {/* {shouldRequestIban() && isValidObject(ibanCurrencies) && ( */}
                <Button
                  icon={<RequestIcon />}
                  color='round-secondary'
                  text={tr('FRONTEND.ACCOUNTS.REQUEST_IBAN_BUTTON', 'Request IBAN')}
                  onClick={handleIbanRequestClick}
                  data-test='button-iban-request'
                />
                {/* )} */}
              </>
            )}
          </AccountHeader>

          {accountsFetching ? (
            <Preloader />
          ) : accountsError ? (
            <ErrorDisplay error={accountsError} />
          ) : (
            <AccountsContainer>
              <DataTable.Table scale2={`${breakpoints.xl}px`}>
                <DataTable.Head>
                  <DataTable.Row>
                    <DataTable.HeadCell>{tr('FRONTEND.ACCOUNTS.COLUMNS.CURRENCY', 'Currency')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.ACCOUNTS.COLUMNS.NAME', 'Account Name')}</DataTable.HeadCell>
                    <DataTable.HeadCell>Account details</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.ACCOUNTS.COLUMNS.ACCOUNT_ID', 'ID')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.ACCOUNTS.COLUMNS.BALANCE', 'Balance')}</DataTable.HeadCell>
                    <DataTable.HeadCell />
                  </DataTable.Row>
                </DataTable.Head>
                <DataTable.Body>
                  {isValidArray(accounts) &&
                    accounts.map(account => (
                      <AccountDataRow account={account} key={account.id} handleEdit={handleEditAccountClick} />
                    ))}
                </DataTable.Body>
              </DataTable.Table>
              <Pagination className='accounts-pagination' {...pagination.register()} />
            </AccountsContainer>
          )}
        </AccountsMain>
      </RowsMainSection>
    </RowsTwoSectionContainer>
  )
}

export default Accounts
