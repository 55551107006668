import { useContext } from 'react'

import toast from 'react-hot-toast'

import { useAccountEditQry } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'

import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import {
  AccountCreateButtons,
  AccountCreateForm,
  AccountCreateTitle,
  AccountCreateWrapper
} from './styled/accountCreateModal.styled'

const AccountEditModal = ({ account, onSuccess }) => {
  const { modalHide } = useContext(GlobalContext)

  const { id: accountId, name: accountName } = account || {}

  const handleSuccessfulChange = () => {
    toast.remove()
    toast.success('Account successfully changed')
    onSuccess()
    modalHide()
  }

  const { formValues, editAccountError, editAccountFetching, handleSubmit } = useAccountEditQry({
    accountId,
    accountName,
    onSuccess: handleSuccessfulChange
  })

  return (
    <AccountCreateWrapper data-test='create-account-modal'>
      <AccountCreateTitle className='title mb-2'>{'Edit Account' /* todo */}</AccountCreateTitle>

      <AccountCreateForm noValidate>
        <Input
          type='text'
          data-test='account-name'
          label={tr('FRONTEND.ACCOUNTS.CREATE.NAME_LABEL', 'Account Name')}
          {...formValues.registerInput('name')}
          autoFocus
        />

        <ErrorDisplay error={editAccountError} />

        <AccountCreateButtons>
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.BUTTONS.SAVE', 'Save')}
            loading={editAccountFetching}
            data-test='button-submit'
            onClick={handleSubmit}
          />

          <Button
            type='button'
            color='secondary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            disabled={editAccountFetching}
            data-test='button-cancel'
            onClick={modalHide}
          />
        </AccountCreateButtons>
      </AccountCreateForm>
    </AccountCreateWrapper>
  )
}

export default AccountEditModal
