import styled from 'styled-components'

import VirtualFrontSide from '../../../images/virtual-card-background-front.png'
import VirtualBackSide from '../../../images/virtual-card-background-back.png'

import PhysicalFrontSide from '../../../images/physical-card-back.png'
import PhysicalBackSide from '../../../images/physical-card-back.png'

import FrostOverlay from '../../../images/frost-overlay.png'

export const RotatingCardSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
  user-select: none;

  .rotating-card-wrapper {
    perspective: 250rem;

    .rotating-card {
      transform-style: preserve-3d;
      width: 34rem;
      height: 19rem;
      transform-origin: center;
      position: relative;
      transition: all 500ms cubic-bezier(0.6, 0.4, 0.3, 1.7);

      .rotating-card-frozen {
        width: 100%;
        height: 100%;
      }

      &.frozen {
        div:not(.rotating-card-frozen) {
          transition: all 0.5s;
          filter: brightness(1.3);
        }

        .card-back {
          > :not(.mastercard-logo, .unionpay-logo, .visa-logo) {
            transition: all 0.5s;
            filter: blur(6px) !important;
          }

          .mastercard-logo,
          .unionpay-logo,
          .visa-logo {
            transition: all 0.5s;
            filter: blur(3px) !important;
          }
        }

        .rotating-card-frozen {
          animation: cardFreezeIn 0.5s;
          transform: translate3d(0, 0, 1.25rem);
          background-color: rgba(0, 175, 255, 0.66);

          .rotating-card-frost {
            opacity: 0.5;
            background-size: cover;
            background-image: url('${FrostOverlay}');
            width: 100%;
            height: 100%;
          }
        }
      }

      &.frozen:not(.physical) {
        .rotating-card-frozen {
          animation: none;
          background-color: transparent;
        }
      }

      &:not(frozen) {
        div:not(.rotating-card-frozen) {
          transition: all 0.5s;
          filter: none;
        }

        .card-back {
          > * {
            transition: all 0.5s;
            filter: none;
          }
        }

        .rotating-card-frozen {
          animation: cardFreezeOut 0.5s;
        }
      }

      & > div {
        position: absolute;
        height: 19rem;
        border-radius: 1.4rem;
        overflow: hidden;
      }

      .cloud-icon {
        position: absolute;
        top: 2rem;
        right: 2rem;
      }

      .card-front {
        width: 34rem;
        background-image: url('${VirtualFrontSide}');
        background-size: cover;
        transform: scale(-1, 1) translate3d(0, 0, -0.2rem);
        padding: 2.2rem 1.6rem;

        svg {
          filter: brightness(0) saturate(100%) invert(1);
        }

        .rotating-card-front-bottom-data {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;
          font-weight: 500;
          display: flex;
          flex-flow: row nowrap;
          gap: 1rem;
          color: white;
          margin-top: 1.6rem;
        }

        .rotating-card-front-top-data,
        .rotating-card-front-middle-data {
          display: flex;
          flex-flow: row nowrap;
          gap: 1rem;
          align-items: center;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 1.92rem;
          color: white;
        }

        .rotating-card-front-middle-data {
          margin-top: 3rem;
        }

        .rotating-card-validity,
        .rotating-card-cvv {
          display: flex;
          flex-flow: column nowrap;

          label {
            opacity: 0.5;
            font-weight: 300;
          }
        }

        .rotating-card-copy-button {
          width: 3.4rem;
          height: 3.4rem;

          padding: 0;
          border: 1px solid rgba(255, 255, 255, 0.1);
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 1rem;

          svg {
            filter: brightness(0) saturate(100%) invert(1);
          }

          .icon-left {
            margin-right: 0;
          }
        }
      }

      .card-back {
        width: 34rem;
        background-image: url('${VirtualBackSide}');
        background-size: cover;
        transform: translate3d(0, 0, 0.2rem);

        .tkb-logo,
        .cloud-icon,
        .mastercard-logo,
        .visa-logo,
        .unionpay-logo,
        .card-back-number {
          position: absolute;
        }

        .tkb-logo {
          top: 2rem;
          right: 2rem;
          filter: brightness(0) saturate(100%) invert(1);
        }

        .cloud-icon {
          left: 2rem;
          top: 2rem;
        }

        .mastercard-logo {
          right: 2rem;
          bottom: 2rem;
        }

        .unionpay-logo {
          right: 2rem;
          bottom: 0.6rem;
        }

        .visa-logo {
          right: 2.5rem;
          bottom: 2rem;
          transform: scale(1.5);
        }

        .card-back-number {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 1.92rem;
          color: white;
          bottom: 2rem;
          left: 2rem;
        }
      }

      .card-left-side {
        width: 0.4rem;
        transform: rotateY(90deg) translate3d(0rem, 0rem, 1rem);
        height: 19rem;
        background-color: #314787;
      }

      .card-right-side {
        width: 0.4rem;
        background-color: #314787;
        height: 19rem;

        transform: rotateY(90deg) translate3d(0, 0rem, 32.5rem);
        transform-origin: bottom;
      }

      .rotating-card-shadow {
        width: 39rem;
        height: 4rem;
        background-image: radial-gradient(rgba(0, 0, 0, 0.2), transparent);
        transform: rotateX(90deg) translate3d(-3rem, 2rem, -18rem);
        transform-origin: bottom;
        border-radius: 35%;
      }

      .card-back,
      .card-left-side,
      .card-right-side {
        pointer-events: none;
      }

      .card-fill {
        @for $i from 1 through 10 {
          $color: red;

          &:nth-child(#{$i}) {
            pointer-events: none;
            width: 34rem;
            background-size: cover !important;
            background-image: url('${VirtualBackSide}');
            transform: translateZ(-2px + (0.4px * ($i - 1)));
            filter: blur(0px) contrast(200%);
          }
        }
      }

      &.physical {
        .card-front {
          background-image: url('${PhysicalFrontSide}');
        }

        .card-back,
        .card-fill {
          background-image: url('${PhysicalBackSide}');

          .tkb-logo {
            filter: none;
          }
        }

        .card-right-side,
        .card-left-side {
          background-color: black;
        }
      }
    }
  }

  @keyframes cardFreezeIn {
    0% {
      background-color: rgba(0, 175, 255, 0.66);
      opacity: 0;
    }

    100% {
      background-color: rgba(0, 175, 255, 0.66);
      opacity: 1;
    }
  }

  @keyframes cardFreezeOut {
    0% {
      background-color: rgba(0, 175, 255, 0.66);
      opacity: 1;
    }

    100% {
      background-color: rgba(0, 175, 255, 0.66);
      opacity: 0;
    }
  }
`
