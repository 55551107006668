import styled from 'styled-components'
import { CommonFlexColumn, CommonFlexRow } from '../jetonStyled'

export const MobileTooltipWrapper = styled.div`
  ${CommonFlexColumn(3)}
  font-size: 1.2rem;

  .text {
    ${CommonFlexColumn(1)}
    border-radius: 10px;
    background: #414141;

    padding: 1.4rem;
    color: #ffffff;
  }

  .label {
    border-radius: 48px;
    border: 1px solid #bfc8d9;
    ${CommonFlexRow(1)}
    align-items: center;

    max-width: 10rem;
    padding: 1rem;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
`
