import { breakpoints } from '../../../constants'
import {
  fixDateOnly,
  monthBackDate,
  //  isValidArray,
  useWindowSize
} from 'mmfintech-commons'
import {
  useGetAggregatedBalanceQuery,
  // , useGetAllCardsQuery
  useGetMerchantQuery
} from 'mmfintech-backend-api'

import Skeleton from 'react-loading-skeleton'
import GraphSection from '../elements/GraphSection'
import AccountSection from '../elements/AccountSection'
import TransactionsSection from '../elements/TransactionsSection'
import { LastAction } from '../../../components/LastAction'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { AccountsPanel } from './AccountsPanel'
import { TotalSpendRow } from '../../../components/TotalSpendRow'
import { DashboardWrapper } from './DashboardAdmin.styled'
import { ColumnLeftSectionContainer, ColumnMainSectionContainer } from '../../../components/jetonStyled'
import CardsBanner from '../elements/CardsBanner'
import { MerchantAccountTypeEnum } from 'mmfintech-commons-types'

export const DashboardAdmin = () => {
  const [width] = useWindowSize()
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, capabilities } = merchant || {}
  const {
    data: aggregatedBalance,
    isFetching: aggregatedBalanceFetching,
    error: aggregatedBalanceError
  } = useGetAggregatedBalanceQuery({
    dateFrom: '',
    dateTo: ''
  })

  return (
    <>
      <ColumnLeftSectionContainer>
        <DashboardWrapper>
          <ErrorDisplay error={aggregatedBalanceError} />

          <div className='left-side'>
            {aggregatedBalanceFetching ? (
              <Skeleton height={40} />
            ) : (
              <AccountSection aggregatedBalance={aggregatedBalance} />
            )}
            {width > breakpoints.xl && (
              <>
                <AccountsPanel />
                {accountType !== MerchantAccountTypeEnum.PROSPECT && capabilities?.enableCardIssuing ? (
                  <CardsBanner />
                ) : (
                  <LastAction />
                )}
              </>
            )}
          </div>
        </DashboardWrapper>
      </ColumnLeftSectionContainer>
      <ColumnMainSectionContainer>
        <DashboardWrapper>
          <div className='right-side'>
            <TotalSpendRow />
            <GraphSection />
            {width < breakpoints.xl && (
              <div className='card-banner-container'>
                <CardsBanner />
              </div>
            )}
            {width > breakpoints.xl && (
              <TransactionsSection
                filter={{
                  from: fixDateOnly(monthBackDate()),
                  to: fixDateOnly(new Date()),
                  page: 0,
                  size: 5,
                  sort: 'reqTime-desc,id-desc'
                }}
              />
            )}
          </div>
        </DashboardWrapper>
      </ColumnMainSectionContainer>
    </>
  )
}
