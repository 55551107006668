import { ReactNode, useContext } from 'react'

import { GlobalContext, tr } from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { ConfirmationButtons, ConfirmationContent, ConfirmationTitle } from './ConfirmationModal.styled'

interface ConfirmationModalProps {
  title?: string
  question: ReactNode
  onConfirm?: () => void
  onCancel?: () => void
  confirmCaption?: string
  buttonColor?: string
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    title = '',
    question,
    onConfirm,
    onCancel,
    confirmCaption = tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm'),
    buttonColor = 'secondary' as const
  } = props

  const { modalHide } = useContext(GlobalContext)

  const handleCancel = () => {
    modalHide()
    typeof onCancel === 'function' && onCancel()
  }

  const handleConfirm = () => {
    modalHide()
    typeof onConfirm === 'function' && onConfirm()
  }

  return (
    <div data-test='confirmation-dialog-modal'>
      <ConfirmationTitle className='title'>
        {title || tr('FRONTEND.MODAL.CONFIRMATION', 'Confirmation')}
      </ConfirmationTitle>
      <ConfirmationContent>{question}</ConfirmationContent>
      <ConfirmationButtons>
        <Button
          type='button'
          color='primary'
          onClick={handleConfirm}
          data-test='button-confirm'
          text={confirmCaption || tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
        />
        <Button
          type='button'
          color={buttonColor as undefined}
          onClick={handleCancel}
          data-test='button-cancel'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
        />
      </ConfirmationButtons>
    </div>
  )
}
