import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { useAppDispatch } from '../../../hooks'
import { OtpContext, tr, useFormValues } from 'mmfintech-commons'
import { loggedIn, paths, useLoginReqMutation } from 'mmfintech-backend-api'

import { JetonLogo } from '../../../components'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import {
  LoginContainer,
  LoginContent,
  LoginContentWrapper,
  LoginWrapper,
  SignupLinkWrapper,
  StyledForm
} from './Login.styled'

export const Login = () => {
  const { setOtpOnSuccess } = useContext(OtpContext)

  const [login, { error: loginError, isLoading: loginFetching, reset: resetLoginReq }] = useLoginReqMutation()

  const dispatch = useAppDispatch()

  const formValues = useFormValues({
    email: { required: true, validation: 'email' },
    password: { required: true }
  })

  const handleFormSubmit = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault()

    if (formValues.areValid()) {
      setOtpOnSuccess(() => (response: any) => {
        dispatch(loggedIn(response))
      })

      try {
        await login(formValues.prepare()).unwrap()
      } catch {}
    }
  }

  const handleResetError = () => {
    if (loginError) {
      resetLoginReq()
    }
  }

  return (
    <LoginWrapper>
      <LoginContainer>
        <JetonLogo />

        <LoginContentWrapper>
          <LoginContent>
            <div className='heading'>{tr('FRONTEND.LOGIN.TITLE', 'Sign In')}</div>

            <StyledForm noValidate onSubmit={handleFormSubmit}>
              <Input
                id='email'
                type='email'
                data-test='email'
                label={tr('FRONTEND.LOGIN.EMAIL', 'E-mail Address')}
                {...formValues.registerInput('email', handleResetError)}
                disabled={loginFetching}
                hideErrorLine
                hideRequired
              />

              <Input
                id='password'
                type='password'
                data-test='password'
                label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
                {...formValues.registerInput('password', handleResetError)}
                disabled={loginFetching}
                autoComplete='off'
                hideErrorLine
                hideRequired
              />

              <div className='link-wrap' data-test='forgot-password'>
                <Link to={paths.forgotPassword()}>{tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}</Link>
              </div>

              <ErrorDisplay error={loginError} />

              <Button
                type='submit'
                text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Log in')}
                loading={loginFetching}
                data-test='button-submit'
              />
            </StyledForm>
          </LoginContent>

          <SignupLinkWrapper>
            {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}
            <Link to={paths.signUp()} data-test='sign-up'>
              <span>{tr('FRONTEND.LOGIN.SIGNUP_BUTTON', 'Sign up')} →</span>
            </Link>
          </SignupLinkWrapper>
        </LoginContentWrapper>

        <span />
      </LoginContainer>
    </LoginWrapper>
  )
}
