import styled from 'styled-components'

import { breakpoints } from '../../constants'
import { CommonFlexColumn } from '../jetonStyled'

export const StyledPaymentButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;

  @media (max-width: ${breakpoints.xl}px) {
    background-color: #ffffff;
    box-shadow: 0px -5px 40px 0px rgba(0, 0, 0, 0.1);
    padding: 1.6rem;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 3;
  }

  .button {
    justify-content: flex-start;
    border-radius: 0.6rem;
    color: #fff;
    max-height: 4rem;
    min-width: 13rem;
    box-shadow: 0;
  }

  .button:hover {
    color: #fff;
  }

  .deposit-button {
    background: #3cd1a3;
  }
  .deposit-button:hover {
    background: #2fc697;
  }

  .send-button {
    background: #6bcdfd;
  }
  .send-button:hover {
    background: #54c5fd;
  }

  .exchange-button {
    background: #699cfc;
  }
  .exchange-button:hover {
    background: #508bfb;
  }

  .mobile-container {
    ${CommonFlexColumn(1)}
    align-items: center;

    font-size: 1.4rem;

    .deposit-icon,
    .send-icon,
    .exchange-icon {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .deposit-icon {
      background-color: #3cd1a3;
    }
    .send-icon {
      background-color: #6bcdfd;
    }
    .exchange-icon {
      background-color: #699cfc;
    }
    &:nth-of-type(2) {
      margin: 0 3rem;
    }
  }
`
