import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { paths, useMerchantAccounts } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import SelectAccount from '../../components/SelectAccount'
import { SuggestTransferModalWrapper } from './styled/suggestTransferModal.styled'

import { AccountBalanceOut } from 'mmfintech-commons-types'

type SuggestTransferModalProps = {
  account?: AccountBalanceOut
}

export function SuggestTransferModal({ account }: SuggestTransferModalProps) {
  const { modalHide }: any = useContext(GlobalContext)

  const { activeAccounts } = useMerchantAccounts()

  const selectedAccount =
    account || (isValidArray(activeAccounts) && activeAccounts.find((a: AccountBalanceOut) => a?.type === 'PRIMARY'))
  const history = useHistory()

  return selectedAccount ? (
    <SuggestTransferModalWrapper>
      <div className='suggest-text'>
        <div className='title'>{tr('FRONTEND.BANKING.SUGGEST_TRANSFER.TITLE', 'Insufficient Balance')}</div>
        <div className='body'>
          {tr(
            'FRONTEND.BANKING.SUGGEST_TRANSFER.BODY',
            'You have insufficient balance in the selected currency account.'
          )}
        </div>
      </div>
      <div className='suggest-actions'>
        <SelectAccount
          staticDisplay
          selectedAccount={selectedAccount}
          label={tr('FRONTEND.BANKING.SUGGEST_TRANSFER.BUTTON_LABEL', 'Account')}
          noShadow
        />
      </div>
      <div className='buttons-container'>
        <Button
          text={tr('FRONTEND.BANKING.SUGGEST_TRANSFER.BUTTON', 'Transfer Between Accounts')}
          color='primary'
          onClick={() => {
            modalHide()
            history.push(paths.banking.sendIntraAccountTransfer())
          }}
        />
        <Button
          color='secondary'
          text='Cancel'
          onClick={() => {
            modalHide()
          }}
        />
      </div>
    </SuggestTransferModalWrapper>
  ) : null
}
