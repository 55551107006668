import cn from 'classnames'

import { tr } from 'mmfintech-commons'

import { Checkbox } from 'mmfintech-portal-commons'
import { CardBrandLogo } from './CardBrandLogo'
import { CardBrandButtonStyled, CardBrandWrapper } from './CardBrandButton.styled'

export const CardBrandButton = ({ brand, selected, onClick }) => (
  <CardBrandButtonStyled onClick={onClick} className={cn({ selected })} data-test={`button-${brand}`}>
    <CardBrandWrapper>
      <CardBrandLogo brand={brand} />
      <span>{tr(`METADATA.CARD_BRAND.${brand}`, brand)}</span>
    </CardBrandWrapper>
    <Checkbox className='card-order-checkbox' checked={selected} onClick={onClick} />
  </CardBrandButtonStyled>
)
