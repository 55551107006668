import { memo, useContext } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'

import fileDownload from 'js-file-download'

import {
  endpoints,
  enotasInvoiceStatuses,
  getInvoiceStatusName,
  invoiceStatuses,
  isInvoiceSendAllowed,
  isInvoiceStatusChangeAllowed,
  paths,
  useFileDownloader,
  useLazyPreviewInvoiceQuery,
  useUpdateInvoiceStatusMutation
} from 'mmfintech-backend-api'
import { formatDate, formatMoney, GlobalContext, isValidArray, tr, useWindowSize } from 'mmfintech-commons'
import { Button, DataTable, NoElementsMessage, Pagination } from 'mmfintech-portal-commons'

import InvoicePreview from '../../components/InvoicePreview'
import InvoiceStatus from '../../components/InvoiceStatus'
import { breakpoints } from '../../constants'
import { ConfirmationModal } from '../../components'
import { IconDownload, IconPreview, InvoiceListWrapper, StatusStatic } from './styled/invoicesList.styled'

import { InvoiceTypeEnum } from 'mmfintech-commons-types'

import TransactionsExportDocumentIcon from '../../images/icons/transactions-export-document.svg?react'

function InvoicesList({ emptyMessage, showCustomerColumn, pagination, downloadCsv, invoices = [] }) {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const history = useHistory()

  const downloader = useFileDownloader()

  const [width] = useWindowSize()
  const [updateInvoice] = useUpdateInvoiceStatusMutation()
  const [getInvoicePreview, { isFetching: invoicePreviewFetching, originalArgs }] = useLazyPreviewInvoiceQuery()

  const changeInvoiceStatus = (invoiceId, status) => {
    if (invoiceId > 0) {
      updateInvoice({ invoiceId, status }).unwrap().then(modalHide)
    }
  }

  const handleInvoice = invoice => {
    const { id, type } = invoice || {}

    switch (type) {
      case InvoiceTypeEnum.ENOTAS:
        history.push(paths.invoices.send(id))
        break

      case InvoiceTypeEnum.THIRD_PARTY:
        history.push(paths.invoices.uploadEdit(id))
        break

      default:
        history.push(paths.invoices.edit(id))
    }
  }

  const handleDownloadClick = (invoiceId: number) => {
    if (!downloader.fetching) {
      downloader.download({
        url: endpoints.invoices.exportInvoiceAsPdf(invoiceId),
        method: 'GET',
        onSuccess: (data: any, filename: string) => {
          fileDownload(data, filename || `invoice-${invoiceId}.pdf`)
        }
      })
    }
  }

  const handleChangeStatus = (index, status) => {
    const current = invoices[index]
    const { id: invoiceId, status: currentStatus } = current || {}

    if (currentStatus !== status) {
      modalShow({
        options: { size: 'medium' },
        content: (
          <ConfirmationModal
            title={tr('FRONTEND.INVOICES.LIST.STATUS.TITLE', 'Confirm Status Change')}
            onConfirm={() => changeInvoiceStatus(invoiceId, status)}
            question={
              <div style={{ marginBottom: '4rem' }}>
                <p style={{ maxWidth: '40rem', display: 'inline-block', lineHeight: '3rem' }}>
                  {tr(
                    'FRONTEND.INVOICES.LIST.STATUS.CONFIRMATION',
                    'Please confirm changing the status of the selected invoice to'
                  )}{' '}
                  <b>&quot;{getInvoiceStatusName(status)}&quot;</b>?
                </p>
                {status === invoiceStatuses.PAID || status === invoiceStatuses.CANCELED ? (
                  <p style={{ fontSize: '1.3rem', fontStyle: 'italic' }}>
                    {tr(
                      'FRONTEND.INVOICES.LIST.STATUS.WARNING',
                      'This change is permanent and you will not be able to revert it.'
                    )}
                  </p>
                ) : null}
              </div>
            }
          />
        )
      })
    }
  }

  const handlePreview = (invoiceId, status) => {
    getInvoicePreview({ invoiceId })
      .unwrap()
      .then(response => {
        if (response) {
          modalShow({
            options: { size: 'extra-large' },
            content: <InvoicePreview status={status} content={response} onClose={modalHide} />
          })
        }
      })
  }

  const invoiceActionIcon = invoice => {
    const { id: invoiceId, status, attachmentId, isOverdue } = invoice || {}

    if (attachmentId > 0) {
      return (
        <span
          className={cn('invoice-preview-button', {
            // 'is-disabled': disableDownload
          })}
          onClick={e => {
            e && e.stopPropagation()
            handleDownloadClick(invoiceId)
          }}>
          <IconDownload title={tr('FRONTEND.INVOICES.CHECKOUT.BUTTON_DOWNLOAD', 'Download Invoice')} />
          <span className='invoice-preview-text'>
            {tr('FRONTEND.INVOICES.CHECKOUT.BUTTON_DOWNLOAD', 'Download Invoice')}
          </span>
        </span>
      )
    }

    if (invoicePreviewFetching && originalArgs.invoiceId === invoiceId) {
      return <TailSpin color='#828282' height={30} width={30} />
    }

    return (
      <span
        className='invoice-preview-button'
        onClick={e => {
          e && e.stopPropagation()
          handlePreview(invoiceId, getInvoiceStatusName(status, isOverdue))
        }}>
        <IconPreview title={tr('FRONTEND.INVOICES.LIST.BUTTON_PREVIEW', 'Preview')} />
        <span className='invoice-preview-text'>{tr('FRONTEND.INVOICES.LIST.BUTTON_PREVIEW', 'Preview')}</span>
      </span>
    )
  }

  return (
    <InvoiceListWrapper>
      {isValidArray(invoices) ? (
        <>
          <DataTable.Table>
            <DataTable.Head>
              <DataTable.Row>
                {showCustomerColumn && (
                  <DataTable.HeadCell>{tr('FRONTEND.INVOICES.LIST.COL_CUSTOMER', 'Customer')}</DataTable.HeadCell>
                )}
                <DataTable.HeadCell>{tr('FRONTEND.INVOICES.LIST.COL_INVOICE_NUMBER', 'Invoice №')}</DataTable.HeadCell>
                <DataTable.HeadCell>
                  {tr('FRONTEND.INVOICES.LIST.COL_ORIGINAL_INVOICE_NO', 'Original Invoice №')}
                </DataTable.HeadCell>
                <DataTable.HeadCell>{tr('FRONTEND.INVOICES.LIST.COL_DATE', 'Date Issued')}</DataTable.HeadCell>
                <DataTable.HeadCell>
                  <span className='col-spacing'>{tr('FRONTEND.INVOICES.LIST.COL_DUE_DATE', 'Due Date')}</span>
                </DataTable.HeadCell>
                <DataTable.HeadCell style={{ textAlign: 'right' }}>
                  {tr('FRONTEND.INVOICES.LIST.COL_AMOUNT', 'Amount')}
                </DataTable.HeadCell>
                <DataTable.HeadCell style={{ textAlign: 'center' }}>
                  {tr('FRONTEND.INVOICES.LIST.COL_STATUS', 'Status')}
                </DataTable.HeadCell>
                <DataTable.HeadCell />
              </DataTable.Row>
            </DataTable.Head>

            <DataTable.Body>
              {invoices.map((data, index) => {
                const {
                  date: invoiceDate,
                  type: invoiceType,
                  total,
                  status,
                  dueDate,
                  currency,
                  isOverdue,
                  payerName,
                  invoiceNumber,
                  ownInvoiceNumber,
                  enotasInvoiceStatus
                } = data || {}
                return (
                  <DataTable.Row
                    key={index}
                    onClick={() =>
                      width >= breakpoints.xl &&
                      isInvoiceSendAllowed(status) &&
                      enotasInvoiceStatuses.isSendAllowed(invoiceType, enotasInvoiceStatus) &&
                      handleInvoice(data)
                    }
                    className={cn({ editable: isInvoiceSendAllowed(status) })}
                    data-test='table-row'>
                    {/* <DataTable.Cell className={cn('invoice-viewed-cell', { 'invoice-hidden': !isOpened })}>
                        {isOpened && (
                          <>
                            <IconOpened title={tr('FRONTEND.INVOICES.LIST.HINT_OPENED', 'Viewed')} />
                            <span className='invoice-viewed-text'>
                              {tr('FRONTEND.INVOICES.LIST.HINT_OPENED', 'Viewed')}
                            </span>
                          </>
                        )}
                      </DataTable.Cell> */}
                    {showCustomerColumn && (
                      <DataTable.Cell
                        className='no-border'
                        caption={tr('FRONTEND.INVOICES.LIST.COL_CUSTOMER', 'Customer')}
                        data-test='invoice-customer'>
                        {payerName}
                      </DataTable.Cell>
                    )}
                    <DataTable.Cell
                      className='no-border'
                      caption={tr('FRONTEND.INVOICES.LIST.COL_INVOICE_NUMBER', 'SpeedyPay No')}
                      data-test='speedy-pay-number'>
                      {invoiceNumber}
                    </DataTable.Cell>
                    <DataTable.Cell
                      className='no-border'
                      caption={tr('FRONTEND.INVOICES.LIST.COL_ORIGINAL_INVOICE_NO', 'Invoice No')}
                      data-test='invoice-number'>
                      {ownInvoiceNumber}
                    </DataTable.Cell>
                    <DataTable.Cell
                      className='no-border'
                      caption={tr('FRONTEND.INVOICES.LIST.COL_DATE', 'Date Issued')}
                      style={{ whiteSpace: 'nowrap' }}
                      data-test='invoice-date-issued'>
                      {formatDate(invoiceDate)}
                    </DataTable.Cell>
                    <DataTable.Cell
                      className='no-border'
                      caption={
                        <span className='col-spacing'>{tr('FRONTEND.INVOICES.LIST.COL_DUE_DATE', 'Due Date')}</span>
                      }
                      style={{ whiteSpace: 'nowrap' }}
                      data-test='invoice-due-date'>
                      <span className={isOverdue ? 'overdue' : 'col-spacing'}>{formatDate(dueDate)}</span>
                    </DataTable.Cell>

                    <DataTable.Cell
                      caption={tr('FRONTEND.INVOICES.LIST.COL_AMOUNT', 'Amount')}
                      className='invoice-amount-cell no-border'
                      data-test='invoice-amount'>
                      {formatMoney(total, currency)}
                    </DataTable.Cell>

                    <DataTable.Cell className='invoice-status-cell no-border' data-test='invoice-status'>
                      {invoiceType === InvoiceTypeEnum.ENOTAS &&
                      enotasInvoiceStatus !== enotasInvoiceStatuses.AUTHORIZED ? (
                        <StatusStatic className={cn('invoice-status-static', 'enotas')} data-test='invoice-status'>
                          {tr(enotasInvoiceStatuses.getKey(enotasInvoiceStatus), enotasInvoiceStatus)}
                        </StatusStatic>
                      ) : isInvoiceStatusChangeAllowed(status, invoiceType) ? (
                        <span className='invoice-status-wrap' onClick={e => e?.stopPropagation()}>
                          <InvoiceStatus
                            value={status}
                            overdue={isOverdue}
                            data-test='invoice-status'
                            onChange={status => handleChangeStatus(index, status)}
                          />
                        </span>
                      ) : (
                        <StatusStatic
                          className={cn('invoice-status-static', status?.toLowerCase())}
                          data-test='invoice-status'>
                          {getInvoiceStatusName(status)}
                        </StatusStatic>
                      )}
                    </DataTable.Cell>

                    <DataTable.Cell
                      className={cn('invoice-edit-cell', 'no-border', {
                        'invoice-hidden': !isInvoiceSendAllowed(status)
                      })}
                      data-test='invoice-edit'>
                      {isInvoiceSendAllowed(status) && (
                        <Button
                          color='round-secondary'
                          text={tr('FRONTEND.BUTTONS.EDIT', 'Edit')}
                          onClick={() => handleInvoice(data)}
                          data-test='invoice-edit-button'
                        />
                      )}
                    </DataTable.Cell>

                    <DataTable.Cell className='invoice-preview-cell no-border' data-test='invoice-preview'>
                      {invoiceActionIcon(data)}
                    </DataTable.Cell>
                  </DataTable.Row>
                )
              })}
            </DataTable.Body>
          </DataTable.Table>

          <div className='pagination-buttons-container'>
            {pagination.totalPages > 1 && <Pagination className='invoices-pagination' {...pagination.register()} />}

            {isValidArray(invoices) && (
              <Button
                className='invoices-export-button'
                color='round-primary'
                icon={<TransactionsExportDocumentIcon />}
                text={tr('FRONTEND.TRANSACTIONS.EXPORT_CSV', 'Export CSV')}
                onClick={downloadCsv}
                data-test='button-export-csv'
              />
            )}
          </div>
        </>
      ) : (
        <NoElementsMessage message={emptyMessage} />
      )}
    </InvoiceListWrapper>
  )
}

export default memo(InvoicesList, (prevProps, nextProps) => isEqual(prevProps, nextProps))

InvoicesList.propTypes = {
  origin: PropTypes.string,
  fetching: PropTypes.bool,
  invoices: PropTypes.array,
  downloadCsv: PropTypes.func,
  pagination: PropTypes.object,
  emptyMessage: PropTypes.string,
  setCurrentPage: PropTypes.func,
  showCustomerColumn: PropTypes.bool
}
