import styled from 'styled-components'

import BackgroundImage from '../../../images/card-podium-background.png'

export const CardPodiumWrapper = styled.div`
  flex: 1;
  border-radius: 1.6rem;
  position: relative;
  overflow: hidden;
  background-color: white;
  margin-top: 24px;

  &.frozen {
    .card-podium-actions {
      justify-content: flex-end;
    }

    .card-balance-value {
      user-select: none;
      filter: blur(15px) !important;
    }
  }
  .card-podium-frozen-cover-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 2;
  }

  .activate-physical-card-container,
  .issuing-physical-card-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    gap: 1.2rem;

    button:hover {
      background-color: white;
    }

    .activate-card-instructions {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      max-width: 38rem;
      text-align: center;
    }
  }

  .issuing-physical-card-container {
    justify-content: center;
  }

  .add-funds-button-container {
    display: flex;
    margin-top: 1.6rem;

    .add-funds-button {
      width: fit-content;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
`

export const CardPodiumContainer = styled.div`
  position: relative;
  padding: 1.6rem;
  display: flex;
  z-index: 3;
  flex-direction: column;
  height: 100%;

  .card-podium-top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button svg {
      filter: brightness(0) saturate(100%) invert(1);
    }

    .core-tabs-wrapper {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.54rem;
      background-color: rgba(white, 0.1);

      .tab-item {
        &.selected {
          color: red;
        }
      }

      .animated-background {
        background-color: white;
      }
    }
  }
`

export const CardPodiumMainSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 3.2rem 1.6rem 1.6rem;
  border-radius: 2.4rem;
  margin-top: 1.6rem;
  background-image: url('${BackgroundImage}');

  .rotating-card-section {
    margin-top: 1.4rem;
  }

  .select-card-arrows {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    button {
      width: 6rem;
      /* background-color: rgb(105, 156, 252); */
      &.disabled {
        pointer-events: none;
      }

      svg {
        filter: brightness(0) saturate(100%) invert(1);
        width: 1.6rem;
        height: 1.6rem;
      }

      span.icon.icon-left {
        margin-right: 0;
      }

      &.prev-card-button svg {
        transform: rotate(90deg);
      }
      &.next-card-button svg {
        transform: rotate(270deg);
      }
    }
  }
  .select-card-dots {
    display: flex;
    gap: 0.6rem;
    justify-content: center;
    margin-top: 3rem;

    .dot-element {
      padding: 0.4rem;
      cursor: pointer;
    }

    .card-dot {
      border-radius: 50%;
      height: 1rem;
      width: 1rem;
      background-color: #c2c9d6;

      &.selected {
        background-color: #314787;
        border: 1px solid #c2c9d6;
      }
    }
  }
`

export const CardPodiumBottomSection = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;

  .card-podium-bottom-section-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;

    .card-podium-balance {
      .card-balance-title {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 500;
        color: rgba(black, 0.5);
      }
      .card-balance-value {
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 100%;
        white-space: nowrap;
        // transition: all 0.8s;
        filter: none;

        span.card-balance-currency {
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 2.08rem;
          margin-left: 0.4rem;
        }
      }
    }

    .card-podium-actions {
      display: flex;
      gap: 4rem;

      .card-podium-action-tuple {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;

        button {
          background-color: rgb(105, 156, 252);

          &:disabled {
            opacity: 0.8;
            cursor: not-allowed;
            background-color: rgb(105, 156, 252);
          }
          border-radius: 50%;
          height: 5.6rem;
          width: 5.6rem;

          min-height: 50px;
          min-width: 50px;

          &:hover {
            background-color: #314787;
          }

          .icon-left {
            margin-right: 0;
          }

          svg {
            path {
              stroke: white;
            }
          }
        }

        .card-podium-action-title {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.68rem;
          color: #777777;
        }
      }

      @media (max-width: 690px) {
        .opaque-button {
          min-width: 5rem;
        }
        .freeze-button {
          width: 50%;
        }
      }
    }
  }

  &.frozen {
    .card-podium-bottom-section-content {
      justify-content: center;
    }
  }
`
