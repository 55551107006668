import styled from 'styled-components'

export const CoreTabsWrapper = styled.div`
  display: inline-flex;
  position: relative;
  height: 5.6rem;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.08rem;
  background-color: #ffffff;
  border-radius: 1rem;
  height: 4rem;

  &.disabled {
    opacity: 0.1;
    cursor: auto;
  }

  p {
    pointer-events: none;
  }

  .tab-item {
    color: #4d5055;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #2b313a;
    }
  }

  .selected {
    z-index: 4;
    color: #0066ff;
  }

  .on-first-load {
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 102, 255, 0.1);
  }

  .tab-item.selected {
    z-index: 4;
    color: #0066ff;
  }

  .animated-background {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    height: 100%;
    background-color: rgba(0, 102, 255, 0.1);
    transition: 0.3s ease-in-out;
    transform: translateX(-50%);

    &.underline {
      height: 100%;
      border-bottom: 2px solid #0066ff;
      background-color: transparent;
    }
  }
`
