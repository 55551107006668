import backButtonIcon from './images/icons/back-button.svg'
import checkedIcon from './images/icons/checkbox-checked.svg'
import radioSelected from './images/icons/radio-selected.svg'
import radioUnselected from './images/icons/radio-unselected.svg'
import brandLogo from './images/logo.svg'

const theme = {
  brandLogo,
  fontName: 'Roboto',

  containerBorderRadius: '1rem',
  containerDropShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',

  mainContentPadding: '0 2.5rem',
  mainContentPadding2: '0 2.5rem 4.5rem',

  titleColor: '#131E3D',

  header: {
    height: '9rem',
    heightThin: '9rem',
    templateColumns: '1fr minmax(32rem, 149rem) 1fr',
    gridColumn: '2 / 3',

    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '1.9rem',
    letterSpacing: 'normal',
    textColor: '#ffffff',
    background: '#131E3D',
    boxShadow: '0 0 10px rgba(150, 150, 150, 0.5)',

    activeBorder: '3px solid #ff3131',
    activeTextColor: '#ffffff',
    activeFontWeight: '500',

    linkTextColor: '#ffffff',
    linkHoverColor: '#ffffff',

    signupBorderRadius: '0.3rem',
    signupBackground: '#ffffff',
    signupBackgroundHover: '#ffffff',
    signupTextColor: '#131E3D',
    signupFontSize: '1.5rem',
    signupFontWeight: '600',
    signupLineHeight: '1.8rem',

    hamburgerTextColor: '#131E3D',
    hamburgerFontSize: '1.6rem',
    hamburgerFontWeight: '500',
    hamburgerLineHeight: '1.9rem',
    hamburgerBackground: '#ffffff',
    hamburgerBoxShadow: '0 0 10px rgba(150, 150, 150, 0.5)',
    hamburgerSignupTextColor: '#ffffff',
    hamburgerSignupBackground: '#131E3D',

    overlayBackground: 'rgba(96, 96, 96, 0.5)',
    overlayFilter: 'none'
  },

  arrowButton: {
    width: '26px',
    height: '22px',
    textColor: 'rgba(19, 30, 61, 1)',
    hoverColor: '#131E3D',
    secondaryHoverColor: '#ffffff'
  },

  button: {
    loadingType: 'TailSpin',
    primary: {
      height: '5rem',
      background: '#131E3D',
      border: 'none',
      borderRadius: '0.8rem',
      boxShadow: '0 0 25px rgba(46, 53, 56, 0.1)',
      color: '#ffffff',
      fontSize: '1.6rem',
      fontWeight: '600',
      iconColor: '#ffffff',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: '#404040',
      hoverBorder: 'none',
      hoverBoxShadow: '0 0 25px rgba(46, 53, 56, 0.1)',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)'
    },
    secondary: {
      height: '5rem',
      background: 'transparent',
      border: 'none',
      borderRadius: '4px',
      boxShadow: 'none',
      color: '#a3b8c2',
      fontSize: '1.6rem',
      fontWeight: '600',
      iconColor: '#a3b8c2',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: 'transparent',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: '#131E3D',

      disabledColor: '#ffffff',
      disabledBackground: 'transparent',
      disabledBorder: 'none'
    },
    alternative: {
      height: '5rem',
      background: '#314787', // '#ff3131',
      border: 'none',
      borderRadius: '4px',
      boxShadow: 'none',
      color: '#ffffff',
      fontSize: '1.8rem',
      fontWeight: '600',
      iconColor: '#ffffff',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: 'rgb(49, 71, 135)', // '#ff5252',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)'
    },
    'round-primary': {
      height: '4rem',
      background: 'transparent',
      border: '1px solid #BFC8D9',
      borderRadius: '10px',
      color: '#131E3D',
      fontSize: '1.2rem',
      fontWeight: '400',
      iconHeight: '1.8rem',
      iconSpacing: '6px',

      hoverBorder: '1px solid #131E3D',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    'round-secondary': {
      height: '4rem',
      background: '#131E3D',
      border: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      color: '#fff',
      fontSize: '1.4rem',
      fontWeight: '400',
      iconColor: '#4f4f4f',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: 'rgb(49, 71, 135)',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    delete: {
      height: '4rem',
      background: 'rgba(247, 59, 59, 0.10)',
      border: '1px solid rgba(247, 59, 59, 0.50)',
      borderRadius: '1rem',
      boxShadow: 'none',
      color: 'rgba(247, 59, 59, 1)',
      fontSize: '1.4rem',
      fontWeight: '400',
      iconColor: '#ffffff',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: 'rgba(247, 59, 59, 0.10)',
      hoverBorder: '1px solid rgba(247, 59, 59, 0.50)',
      hoverBoxShadow: 'none',
      hoverColor: 'none',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    }
  },

  checkbox: {
    checkedIconBackgroundColor: '#131E3D',
    checkedIconBorderColor: '#131E3D',
    iconBackground: '#ffffff',
    iconBorder: '1px solid #dadada',
    iconBorderRadius: '0.3rem',
    iconSize: '1.6rem',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: '500',
    textColor: '#a3b8c2'
  },

  contentWrapper: {
    backgroundColor: '#F6F7F8',
    textColor: '#131E3D',
    staticColor: '#a3b8c2',
    gridRowGap: '0',
    gridRowGap2: '0',
    gridRowGap3: '0'
  },

  datepicker: {
    container: {
      backgroundColor: '#ffffff',
      borderColor: 'rgba(163, 184, 194, 0.15)', // rgba(163, 184, 194, 0.3)
      borderRadius: '6px',
      boxShadow: '0 0 25px rgba(46, 53, 56, 0.1)',
      fontSize: '1.2rem',
      padding: '0 0 1rem'
    },
    dayName: {
      fontSize: '1.2rem',
      fontWeight: '600',
      lineHeight: 'normal',
      textColor: '#131E3D',
      marginTop: '1rem',
      marginBottom: '0.5rem'
    },
    header: {
      arrowSize: '1.2px',
      arrowTop: '1rem',
      arrowLeftRight: '1rem',
      backgroundColor: 'rgba(163, 184, 194, 0.1)',
      borderColor: 'rgba(163, 184, 194, 0.15)',
      fontSize: '1.4rem',
      fontWeight: '500',
      lineHeight: '3rem',
      textColor: '#131E3D',
      padding: '1rem 1rem 0'
    },
    cells: {
      border: '1px solid transparent',
      borderRadius: '4px',
      height: '3rem',
      width: '3rem',
      fontSize: '1.4rem',
      fontWeight: 'normal',
      textColor: '#131E3D',
      outsideColor: '#939ea7',
      margin: '2px 4px',
      hoverBackground: 'transparent',
      hoverBorder: '1px solid #85a0ad',
      hoverTextColor: '#131E3D',
      selectedBackground: '#131E3D',
      selectedBorder: '1px solid #131E3D',
      selectedColor: 'white'
    }
  },

  dataTable: {
    body: {
      backgroundColor: 'white',
      borderRadius: '10px',
      textColor: '#131E3D',
      fontSize: '1.6rem',
      fontWeight: '500',
      infoLineColor: '#939ea7',
      border: 'none'
    },
    head: {
      textColor: '#a4a6b0',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      border: 'none',
      borderBottom: 'none'
    },
    foot: {
      backgroundColor: 'transparent'
    },
    row: {
      borderBottom: '1px solid #EDEEEF',
      hover: {}
    },
    cell: {
      hover: {},
      backgroundColor: 'transparent',
      fontSize: '1.2rem',
      fontWeight: '400'
    },
    headCell: { hover: {}, backgroundColor: 'transparent' },
    hover: {
      backgroundColor: '#EDEEEF',
      textColor: '#131E3D'
    }
  },

  dropTarget: {
    background: 'rgba(255, 255, 255, 0.9)',
    border: '2px solid #828282',
    borderRadius: '3px',
    textColor: '#131E3D',
    fontSize: '1.6rem',
    fontWeight: 'normal'
  },

  errorDisplay: {
    borderColor: 'transparent',
    borderBottomColor: '#ff3131',
    textColor: '#ff3131',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '1.7rem',
    margin: '1rem 0'
  },

  finalMessage: {
    fontSize: '1.6rem',
    fontWeight: '500',
    textColor: '#131E3D',
    infoTextColor: '#2f80ed',
    dangerTextColor: '#ee2b2b',
    successTextColor: '#59b300',
    padding: '0 2.5rem 4.5rem',
    contentPadding: '2.5rem 0 0'
  },

  gridTable: {
    border: '1px solid #e5e5e5',
    fontSize: '1.4rem',
    fontSize2: '1.6rem',
    fontWeight: '500',
    lineHeight: '1.7rem',
    lineHeight2: '2rem',
    labelColor: '#85a0ad',
    valueColor: '#131E3D',
    negativeColor: '#ee2b2b',
    positiveColor: '#59b300',
    rowPadding: '1rem 0',
    totalFontSize: '2rem',
    totalFontSize2: '2.4rem',
    totalFontWeight: 'bold',
    totalLineHeight: '2.4rem',
    totalLineHeight2: '3rem'
  },

  input: {
    label: {
      fontFamily: "'Roboto'",
      textColor: '#131E3D',
      fontSize: '1.2rem',
      fontWeight: '400',
      lineHeight: 'normal',
      textTransform: 'none',
      marginBottom: '0.5rem',
      asteriskColor: '#ff3131',
      asteriskMargin: '3px',
      asteriskPosition: 'right',
      paddingWithIcon: '2.5rem',
      paddingRequired: '1.7rem'
    },
    error: {
      textColor: '#ff3131',
      padding: '0',
      marginTop: '1rem'
    },
    wrapper: {
      marginBottom: '2rem'
    },
    container: {
      textColor: '#131E3D',
      fontSize: '1.4rem',
      outerFontSize: '1.6rem',
      fontWeight: '400',
      lineHeight: '1.7rem',
      background: 'white',
      border: '1px solid rgba(163, 184, 194, 0.3)',
      borderWidth: '1px',
      borderRadius: '1rem',
      padding: '0 2rem',
      iconColor: '#85a0ad',
      errorBorderColor: 'rgba(163, 184, 194, 0.3)',
      errorBorderWidth: '1px',
      focusedBorderColor: 'rgba(163, 184, 194, 0.3)',
      focusedBorderWidth: '1px'
    },
    placeholder: {
      // textColor: 'transparent',
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '1.7rem'
    },
    dropDown: {
      controlBorder: 'none',
      controlBorderRadius: '10px',
      controlPadding: '0',
      indicatorColor: '#85a0ad',
      menuBackground: 'hsl(0, 0%, 100%)',
      menuBorder: 'none',
      menuBorderRadius: '10px',
      menuMargin: '8px 0',
      menuPadding: '0',
      openedBackground: 'transparent',
      openedBorder: 'none',
      openedBorderRadius: '0',
      outerOpenedBorderRadius: '10px',
      openedColor: '#131E3D',
      optionBorderRadius: '0',
      optionColor: '#131E3D',
      optionFontSize: '1.6rem',
      optionFontWeight: 'normal',
      optionLineHeight: 'normal',
      optionMargin: '0',
      optionPadding: '1rem',
      optionBackground: 'white',
      focusedOptionColor: 'rgba(19, 30, 61, 1)',
      focusedOptionBackground: 'rgba(191, 200, 217, 0.3)',
      buttonColor: '#131E3D',
      buttonTextAlign: 'left',
      buttonBorderRadius: '0',
      buttonBackground: 'white',
      buttonMargin: '0',
      buttonWidth: 'auto'
    },
    settings: {
      height: '4rem',
      outerHeight: '5.6rem',
      singleSelect: false,
      autofillBackground: 'white',
      dropDownArrowColor: '#85a0ad',
      textareaMargin: '0',
      textareaPadding: '1rem 0',
      hideLabelWhenEmpty: false,
      showPasswordWithText: false
    }
  },

  loader: {
    spinnerColor: '#828282',
    spinnerSize: 55,
    spinnerType: 'TailSpin',
    loaderColor: '#131E3D',
    loaderSize: 75
  },

  noElementsMessage: {
    background: 'transparent',
    border: 'none',
    borderRadius: '0',
    boxShadow: 'none',
    minHeight: '15rem',
    padding: '2rem',
    textColor: '#a3b8c2',
    fontSize: '1.6rem',
    fontWeight: 'normal',
    lineHeight: '2.4rem'
  },

  progressBar: {
    background: '#d9d9d9',
    colorGreen: 'rgba(68, 204, 0, 0.5)',
    colorRed: 'rgba(255, 49, 49, 0.5)'
  },

  pagination: {
    marginPages: 2,
    minPagesVisible: 0,
    noArrowButtons: true,
    showPageLabel: false,
    fontSize: '1.2rem',
    fontWeight: '500',
    lineHeight: '1.5rem',
    textColor: '#85a0ad',
    backgroundColor: 'transparent',
    buttonBorderColor: 'rgba(163, 184, 194, 0.3)',
    buttonRadius: '50%',
    buttonMargin: '1rem',
    buttonSize: '3rem',
    buttonTextColor: '#85a0ad',
    buttonHoverBorderColor: 'rgba(163, 184, 194, 0.3)',
    buttonHoverTextColor: '#131E3D',
    buttonActiveBackground: '#131E3D',
    buttonActiveBorderRadius: '50%',
    buttonActiveBoxShadow: 'none',
    buttonActiveTextColor: 'white'
  },

  radioButton: {
    iconSize: '2.4rem',
    iconSpacing: '1.6rem',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '2rem',
    textColor: '#828282',
    loadingColor: '#828282',
    loadingSize: '30'
  },

  switchInput: {
    width: '4.6rem',
    height: '2.6rem',
    border: 'none',
    borderRadius: '2.3rem',

    color: '#ffffff',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '120%',

    ballSize: '2.2rem',
    ballBackground: '#ffffff',
    ballBoxShadow: '0 2px 2px rgba(0, 0, 0, 0.24)',

    checkedBackground: '#131E3D',
    uncheckedBackground: '#f4f6f6',

    uncheckedBallColor: '#ffffff',
    uncheckedBorder: 'none',

    disabledColor: '#4f4f4f',
    disabledBallColor: '#ffffff',
    disabledBorder: 'transparent',
    disabledBackground: '#131E3D',
    disabledOpacity: '0.5'
  },

  sidebar: {
    width: 130, // in px
    widthCollapsed: 50 // in px
  },

  backButtonIcon,
  backButtonIconHover: backButtonIcon,
  backButtonIconSecondary: backButtonIcon,

  checkedIcon,
  radioSelected,
  radioUnselected
}

export default theme
