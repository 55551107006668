import styled from 'styled-components'

import { devices } from '../../constants'

export const LogoWrapper = styled.div`
  width: 20rem;
  height: 10rem;
  position: relative;

  @media ${devices.xs} {
    justify-self: initial;
  }
  @media ${devices.md} {
    justify-self: center;
  }

  .logo-remove-item {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    display: none;
    cursor: pointer;
  }

  .image-item:hover .logo-remove-item {
    display: inline-block;
  }

  .logo-loader {
    display: inline-block;
    width: 20rem;
    height: 10rem;
  }

  .logo-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    .image-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .image-item,
    .image-item > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .text-center > svg {
    flex: 0.5;
  }
  .text-center > span {
    flex: 2;
  }
`

export const LogoButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  background-color: rgba(0, 102, 255, 0.05);

  cursor: pointer;

  font-size: 1.2rem;
  font-weight: 500;

  &.bordered {
    border-radius: 3px;
    border: 1px dashed rgba(0, 102, 255, 0.5);
    width: 20rem;
    height: 10rem;
  }
`
