import { CardBrandEnum } from 'mmfintech-commons-types'

import MastercardLogo from '../../images/icons/cardTypes/mastercard-card-type.svg?react'
import MastercardPureLogo from '../../images/icons/cardTypes/mastercard-pure-logo.svg?react'
import UnionpayLogo from '../../images/icons/cardTypes/unionpay-card-type.svg?react'
import UnionpayPureLogo from '../../images/icons/cardTypes/unionpay-pure-icon.svg?react'
import VisaLogo from '../../images/icons/cardTypes/visa-card-type.svg?react'
import VisaPureLogo from '../../images/icons/cardTypes/visa-pure-icon.svg?react'

interface CardBrandLogoProps {
  brand: string
  logoOnly?: boolean
}

export const CardBrandLogo = ({ brand, logoOnly }: CardBrandLogoProps) => {
  switch (brand) {
    case CardBrandEnum.MASTERCARD:
      return logoOnly ? <MastercardPureLogo /> : <MastercardLogo />

    case CardBrandEnum.VISA:
      return logoOnly ? <VisaPureLogo /> : <VisaLogo />

    case CardBrandEnum.UNIONPAY:
      return logoOnly ? <UnionpayPureLogo /> : <UnionpayLogo />

    default:
      return null
  }
}
