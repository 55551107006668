import { useContext, useEffect, useState } from 'react'

import fileDownload from 'js-file-download'

import { endpoints, useFileDownloader, useGetMerchantQuery } from 'mmfintech-backend-api'
import {
  compareDates,
  currentDay,
  currentMonth,
  currentYear,
  firstDateOfMonth,
  fixDateOnly,
  formatDate,
  GlobalContext,
  lastDayOfMonth,
  monthOptions,
  prepareDate,
  todayDate,
  tr,
  useFormValues
} from 'mmfintech-commons'

import DatePicker from 'react-datepicker'
import SelectAccount from '../../../components/SelectAccount'

import { Button, Checkbox, ErrorDisplay, Input, RadioButtonGroup } from 'mmfintech-portal-commons'
import { DateContainer, DateWrapper, StatementWrapper, StyledForm } from './TransactionsExportModal.styled'

import { StatementPeriodTypeEnum } from 'mmfintech-commons-types'

import calendarImage from '@images/icons/export-calendar.svg'

export enum exportTypes {
  StatementPDF = 'StatementPDF',
  StatementSCV = 'StatementSCV',
  TransactionsSCV = 'TransactionsSCV'
}

export const TransactionsExportModal = ({ type, account, accounts, dateFrom, dateTo, filter }) => {
  const { modalHide } = useContext(GlobalContext)
  const { data: merchant } = useGetMerchantQuery()

  const downloader = useFileDownloader()

  const isTransactionsExport = type === exportTypes.TransactionsSCV

  const [error, setError] = useState(null)
  const [periodType, setPeriodType] = useState<keyof typeof StatementPeriodTypeEnum>(StatementPeriodTypeEnum.CUSTOM)
  const [selectedAccount, setSelectedAccount] = useState(account)
  const [showTransactionsFromRelatedMerchants, setShowTransactionsFromRelatedMerchants] = useState<boolean>(false)

  const formValues = useFormValues({
    quarter: { value: 'Q1' },
    month: { value: currentMonth() + 1 },
    year: { value: currentYear() },
    startDate: { value: dateFrom || firstDateOfMonth(new Date()) },
    endDate: { value: dateTo || todayDate() },
    period: {}
  })

  // const fiscalMonths = ['M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'M9', 'M10', 'M11', 'M12']
  const fiscalQuarters = ['Q1', 'Q2', 'Q3', 'Q4']

  const cleanupPeriodError = () => formValues.setError('period', null)

  const getYears = () => {
    const { createdOn } = merchant || {}
    const year2 = prepareDate(createdOn).getFullYear()
    const res = []
    for (let y = currentYear(); y >= year2; y -= 1) {
      res.push(y)
    }
    return res
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!isTransactionsExport && !selectedAccount) {
      setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_ACCOUNT_MANDATORY', 'Please select an account'))
      return
    }

    let period

    switch (periodType) {
      case StatementPeriodTypeEnum.MONTHLY: {
        const startDate = new Date(formValues.getValue('year'), formValues.getValue('month') - 1, 1)
        if (compareDates(startDate, todayDate()) > 0) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        const endDate = lastDayOfMonth(startDate)
        if (endDate.getFullYear() === currentYear() && endDate.getMonth() === currentMonth()) {
          endDate.setDate(currentDay())
        }
        if (compareDates(endDate, todayDate()) > 0) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        period = {
          periodType: StatementPeriodTypeEnum.CUSTOM,
          startDate: fixDateOnly(startDate),
          endDate: fixDateOnly(endDate)
        }
        // period = {
        //   periodType,
        //   month: formValues.getValue('month'), // [ M1, M2, M3, M4, M5, M6, M7, M8, M9, M10, M11, M12 ]
        //   year: formValues.getValue('year')
        // }
        break
      }
      case StatementPeriodTypeEnum.QUARTERLY:
        period = {
          periodType,
          quarter: formValues.getValue('quarter'), // [ Q1, Q2, Q3, Q4 ]
          year: formValues.getValue('year')
        }
        break
      case StatementPeriodTypeEnum.YEARLY:
        period = {
          periodType,
          year: formValues.getValue('year')
        }
        break
      default:
        if (
          compareDates(formValues.getValue('startDate'), todayDate()) > 0 ||
          compareDates(formValues.getValue('endDate'), todayDate()) > 0
        ) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        period = {
          periodType: StatementPeriodTypeEnum.CUSTOM,
          startDate: fixDateOnly(formValues.getValue('startDate')),
          endDate: fixDateOnly(formValues.getValue('endDate'))
        }
    }

    const data = {
      ...(isTransactionsExport && showTransactionsFromRelatedMerchants
        ? { showTransactionsFromRelatedMerchants }
        : null),
      ...(selectedAccount && !isTransactionsExport
        ? { accountId: selectedAccount?.id, period }
        : {
            ...filter,
            from: period.startDate,
            sort: 'reqTime-desc,id-desc',
            to: period.endDate,
            ...(selectedAccount ? { accountId: selectedAccount?.id } : {})
          })
    }

    try {
      switch (type) {
        case exportTypes.StatementPDF:
          void downloader.download({
            url: endpoints.transactions.getStatementPDF(),
            method: 'POST',
            data,
            onSuccess: (data: any, filename: string) => {
              fileDownload(data, filename || 'balance-statement.pdf', 'application/pdf')
              modalHide()
            }
          })
          break

        case exportTypes.StatementSCV:
          void downloader.download({
            url: endpoints.transactions.getStatementCSV(),
            method: 'POST',
            data,
            onSuccess: (data: any, filename: string) => {
              fileDownload(data, filename || 'balance-statement.csv', 'text/csv')
              modalHide()
            }
          })
          break

        default:
          void downloader.download({
            url: endpoints.transactions.getReportCSV(),
            method: 'POST',
            data,
            onSuccess: (data: any, filename: string) => {
              fileDownload(data, filename || 'transactions.csv', 'text/csv')
              modalHide()
            }
          })
      }
    } catch (_err) {}
  }

  useEffect(() => {
    if (downloader.error) {
      formValues.handleErrors(downloader.error)
    }
  }, [downloader.error])

  return (
    <StatementWrapper data-test='balance-statement-period-modal'>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <div className='title'>
          {isTransactionsExport
            ? tr('FRONTEND.STATEMENT.EXPORT.TITLE_CSV', 'Transactions export period')
            : tr('FRONTEND.STATEMENT.EXPORT.TITLE', 'Balance statement period')}
        </div>

        <SelectAccount
          noShadow
          accounts={accounts}
          label={
            <>
              {tr('FRONTEND.STATEMENT.EXPORT.ACCOUNT_LABEL', 'Account')}
              <span className='asterisk'>*</span>
            </>
          }
          showAllAccounts={type === exportTypes.TransactionsSCV}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />

        <RadioButtonGroup
          options={[
            {
              value: StatementPeriodTypeEnum.MONTHLY,
              label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_MONTHLY', 'Monthly'),
              dataTest: StatementPeriodTypeEnum.MONTHLY
            },
            // {
            //   value: statementPeriodType.QUARTERLY,
            //   label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_QUARTERLY', 'Quarterly'),
            //   dataTest: statementPeriodType.QUARTERLY
            // },
            // {
            //   value: statementPeriodType.YEARLY,
            //   label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_YEARLY', 'Yearly'),
            //   dataTest: statementPeriodType.YEARLY
            // },
            {
              value: StatementPeriodTypeEnum.CUSTOM,
              label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_CUSTOM', 'Custom'),
              dataTest: StatementPeriodTypeEnum.CUSTOM
            }
          ]}
          selected={periodType}
          setSelected={val => {
            setPeriodType(val as keyof typeof StatementPeriodTypeEnum)
            cleanupPeriodError()
          }}
        />

        {periodType === StatementPeriodTypeEnum.CUSTOM ? (
          <div className='period-wrap'>
            <DateContainer>
              <label>
                {tr('FRONTEND.STATEMENT.EXPORT.DATE_FROM_LABEL', 'From')}
                <span className='asterisk'>*</span>
              </label>
              <DatePicker
                locale={'en'}
                maxDate={todayDate()}
                selected={formValues.getValue('startDate')}
                onChange={date => {
                  formValues.setValue('startDate', date)
                  cleanupPeriodError()
                }}
                calendarStartDay={1}
                customInput={
                  <DateWrapper>
                    <img src={calendarImage} alt='From' />
                    <span>{formatDate(formValues.getValue('startDate'))}</span>
                  </DateWrapper>
                }
              />
            </DateContainer>
            <DateContainer>
              <label>
                {tr('FRONTEND.STATEMENT.EXPORT.DATE_TO_LABEL', 'To')}
                <span className='asterisk'>*</span>
              </label>
              <DatePicker
                locale='en'
                maxDate={todayDate()}
                selected={formValues.getValue('endDate')}
                onChange={date => {
                  formValues.setValue('endDate', date)
                  cleanupPeriodError()
                }}
                calendarStartDay={1}
                customInput={
                  <DateWrapper>
                    <img src={calendarImage} alt='To' />
                    <span>{formatDate(formValues.getValue('endDate'))}</span>
                  </DateWrapper>
                }
              />
            </DateContainer>
          </div>
        ) : (
          <div className='year-month'>
            <Input
              type='select'
              data-test='year'
              menuPosition='fixed'
              parentClassName='year-wrapper'
              label={tr('FRONTEND.STATEMENT.EXPORT.YEAR_LABEL', 'Year')}
              options={getYears().map(year => ({ label: year, value: year }))}
              {...formValues.registerInput('year', cleanupPeriodError)}
              required
            />

            {/* <Input
                type='select'
                data-test='month'
                menuPosition='fixed'
                label={'Fiscal month'}
                options={fiscalMonths.map(m => ({ label: m, value: m }))}
                {...formValues.register('month')}
              /> */}
            {periodType === StatementPeriodTypeEnum.MONTHLY && (
              <Input
                type='select'
                data-test='month'
                menuPosition='fixed'
                label={tr('FRONTEND.STATEMENT.EXPORT.MONTH_LABEL', 'Month')}
                options={monthOptions()}
                {...formValues.registerInput('month', cleanupPeriodError)}
                required
              />
            )}

            {periodType === StatementPeriodTypeEnum.QUARTERLY && (
              <Input
                type='select'
                data-test='quarter'
                label={tr('FRONTEND.STATEMENT.EXPORT.QUARTER_LABEL', 'Fiscal quarter')}
                menuPosition='fixed'
                options={fiscalQuarters.map(q => ({ label: q, value: q }))}
                {...formValues.registerInput('quarter', cleanupPeriodError)}
              />
            )}
          </div>
        )}

        <div className='mb-2'>
          <Checkbox
            checked={showTransactionsFromRelatedMerchants}
            onClick={() => setShowTransactionsFromRelatedMerchants(prevState => !prevState)}
            label={tr('FRONTEND.STATEMENT.EXPORT.RELATED_MERCHANTS', 'Show transactions from related merchants')}
          />
        </div>

        <ErrorDisplay error={[downloader.error, error, formValues.getError('period')]} />

        <Button
          type='submit'
          color='primary'
          text={tr('FRONTEND.STATEMENT.EXPORT.GENERATE_BUTTON', 'Generate Statement')}
          loading={downloader.fetching}
          disabled={downloader.fetching}
          data-test='button-generate'
        />

        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          onClick={modalHide}
          disabled={downloader.fetching}
          data-test='button-cancel'
        />
      </StyledForm>
    </StatementWrapper>
  )
}
