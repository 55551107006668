import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1rem;

  .button {
    height: 3.2rem;
  }

  @media (min-width: 480px) {
    flex-flow: row nowrap;
    justify-content: center;

    .button {
      width: auto;
      margin-bottom: 0;
    }
  }

  @media (min-width: 1200px) {
    grid-column: 3;
    justify-content: flex-end;
    margin-left: 0;
  }

  @media (min-width: 1280px) {
    margin-left: auto;
  }
`
